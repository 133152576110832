import React, { useState, useEffect } from "react";
import bootstrap from "../../assets/libraries/bootstrap/js/bootstrap";
import { api } from "../../utils/Api";
import Select from "react-select";
import makeAnimated from "react-select/animated";

let myModal = {};

export default function ModalOrderCancel(props) {
  const [cancelData, setCancelData] = useState({ action: "close" });
  const [orderDetails, setOrderDetails] = useState(null);
  const [status, setStatus] = useState("cancel_as_customer");
  const [makeRefund, setMakeRefund] = useState(false);
  const [refundAmount, setRefundAmount] = useState(0);
  const [refundReason, setRefundReason] = useState([]);
  const [selectedReasons, setSelectedReasons] = useState([]);
  const [notifications, setNotifications] = useState({
    customer_notification: true,
    customer_call_notification: true,
    seller_notification: true,
    delivery_partner_notification: true,
  });
  const [error, setError] = useState("");

  const animatedComponents = makeAnimated();

  useEffect(() => {
    let modalElement = document.getElementById("cancelConfirmModalID");
    modalElement.addEventListener("hidden.bs.modal", () => {
      props.close(cancelData);
    });
  }, []);

  useEffect(() => {
    if (props.open) {
      myModal = new bootstrap.Modal(
        document.getElementById("cancelConfirmModalID"),
        {}
      );
      fetchCancelEligibility(props.data.pass_data.id);
      myModal.show();
    }
  }, [props.open]);

  const fetchCancelEligibility = async (orderId) => {
    let response = await api("/orders/check_cancel", { post: { id: orderId } });
    if (response.status_code === 200) {
      setOrderDetails(response.response);
      setRefundAmount(parseFloat(response.response.order_amount));
    }
  };

  const handleCancelOrder = async () => {
   

    if (makeRefund && refundAmount > parseFloat(orderDetails.order_amount)) {
      setError("Refund amount cannot exceed order amount.");
      return;
    }

    let postData = {
      post: {
        id: props.data.pass_data.id,
        status,
        refund_amount: makeRefund ? refundAmount : 0,
        refund_reason: selectedReasons.map((reason) => reason.value),
        customer_notification: notifications.customer_notification ? 1 : 0,
        customer_call_notification: notifications.customer_call_notification ? 1 : 0,
        seller_notification: notifications.seller_notification ? 1 : 0,
        delivery_partner_notification: notifications.delivery_partner_notification ? 1 : 0,
      },
    };
    console.log(postData);

    let response = await api("/orders/cancel_store_order_v2", postData);
    if (response.status_code === 200) {
    myModal.hide();
    props.on_ok();
    showToast("Order cancelled successfully!");
    }
  };

  const showToast = (message) => {
    const toastContainer = document.getElementById("toastContainer");
    const toastElement = document.createElement("div");
    toastElement.className = "toast align-items-center text-white bg-success m-2";
    toastElement.innerHTML = `
      <div class="d-flex">
        <div class="toast-body">${message}</div>
        <button type="button" class="btn-close me-2 m-auto" data-bs-dismiss="toast"></button>
      </div>
    `;
    toastContainer.appendChild(toastElement);
    new bootstrap.Toast(toastElement).show();
  };

  const refundReasonOptions = orderDetails?.reasons?.map((reason) => ({
    value: reason.id,
    label: reason.name,
  }));

  return (
    <>
      <div id="toastContainer" className="position-fixed top-0 end-0 p-3"></div>

      <div
        className="modal fade"
        id="cancelConfirmModalID"
        tabIndex={-1}
        aria-labelledby="ModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Cancel Confirmation</h5>
              <button type="button" className="btn-close" onClick={() => myModal.hide()}></button>
            </div>
            <div className="modal-body">
              {orderDetails && orderDetails.cancel_eligible.eligible ? (
                <>
                  <div className="m-2">
                    <h6>Cancel As:</h6>
                    <label className="me-2">
                      <input
                        type="radio"
                        value="cancel_as_customer"
                        checked={status === "cancel_as_customer"}
                        onChange={() => setStatus("cancel_as_customer")}
                      />{" "}
                      Customer
                    </label>
                    <label className="me-2">
                      <input
                        type="radio"
                        value="cancel_as_seller"
                        checked={status === "cancel_as_seller"}
                        onChange={() => setStatus("cancel_as_seller")}
                      />{" "}
                      Seller
                    </label>
                    <label className="me-2">
                      <input
                        type="radio"
                        value="cancel_as_admin"
                        checked={status === "cancel_as_admin"}
                        onChange={() => setStatus("cancel_as_admin")}
                      />{" "}
                      Admin
                    </label>
                  </div>

                  <div className="m-2">
                    <label>
                      <input
                        type="checkbox"
                        checked={makeRefund}
                        onChange={() => setMakeRefund(!makeRefund)}
                      />{" "}
                      Make Refund
                    </label>
                  </div>

                  {makeRefund && (
                    <div className="m-2">
                      <label>Refund Amount:</label>
                      <input
                        type="number"
                        className="form-control"
                        value={refundAmount}
                        onChange={(e) => setRefundAmount(parseFloat(e.target.value))}
                      />
                      {error && <div className="text-danger">{error}</div>}
                    </div>
                  )}

                  <div className="m-2">
                    <Select
                      components={animatedComponents}
                      className="basic-multi-select"
                      classNamePrefix="select"
                      placeholder="Select Refund Reasons"
                      isMulti
                      value={selectedReasons}
                      options={refundReasonOptions}
                      onChange={setSelectedReasons}
                    />
                  </div>

                  <div className="m-2">
                    <h6>Notifications:</h6>
                    {Object.keys(notifications).map((key) => (
                      <div key={key} className="form-check">
                        <input
                          type="checkbox"
                          id={key}
                          className="form-check-input"
                          checked={notifications[key]}
                          onChange={() =>
                            setNotifications((prev) => ({
                              ...prev,
                              [key]: !prev[key],
                            }))
                          }
                        />
                        <label className="form-check-label" htmlFor={key}>
                          {key.replace(/_/g, " ").replace(/\b\w/g, (l) => l.toUpperCase())}
                        </label>
                      </div>
                    ))}
                  </div>
                </>
              ) : (
                <p>Order is not eligible for cancellation.</p>
              )}
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-secondary" onClick={() => myModal.hide()}>
                Close
              </button>
              {orderDetails && orderDetails.cancel_eligible.eligible && (
                <button type="button" className="btn btn-primary" onClick={handleCancelOrder}>
                  Confirm Cancel
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
