import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import { MaterialCommunityIcons } from "@expo/vector-icons";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import { api } from "../../utils/Api";
import ModalAddGallery from "./ModalAddGallery";
import { useNavigate } from "react-router-dom";
import toast, { Toaster } from "react-hot-toast";
import { Ionicons } from "@expo/vector-icons";

const animatedComponents = makeAnimated();

const outlet_initial_value = {
  selected_user: {
    id: "",
    name: "",
  },
  user_id: "",
  selected_roles: [],
  roles: { roles: [], custom_roles: [] },

  email: "",
  cat_name: "",
  image_url: "",
  image: "",
};

let myModal = {};

export default function CreateCategory(props: any) {
  const [close_data, SetCloseData] = useState({
    action: "close",
  });
  const [id_value, SerIdValue] = useState(useParams());

  const [outlet_data, SetOutletData] = useState(outlet_initial_value);
  const [user_role_options, SetUserRoleOptions] = useState([]);

  const [page_no, SetPageNo] = useState(1);

  const [timer, SetTimer] = useState(null);
  const WAIT_INTERVAL = 700;
  const [user_errors, SetUserErrors] = useState(false);
  const [errors, setErrors] = useState({});
  const [modal_image, SetModalImage] = useState({
    is_open: false,
    selected_images: [],
  });
  //OutletData End

  const navigate = useNavigate();
  useEffect(() => {
    get_roles();

 
  }, []);

  async function get_roles() {
    let data_res = await api("/orders/sectors");
    let role_set_value = [];

    if(id_value?.sec_id !=0){
    let desiredSector = data_res.response.sectors.find(sector => sector.id == id_value.sec_id);
     let pre_roles = {
      value: desiredSector.id,
      label: desiredSector.name
  };
  
  SetOutletData((oldValues) => ({
    ...oldValues,
    selected_roles: pre_roles,
  }));
}
  
    data_res.response.sectors.map((role_item, role_index) => {
      role_set_value.push({
        label: role_item.name,
        value: role_item.id,
        //   type: "roles",
      });
    });
    SetUserRoleOptions(role_set_value);
  }

  function onImgSelect() {
    SetModalImage((prevValue: any) => {
      prevValue.is_open = !prevValue.is_open;
      // prevValue.selected_images = data.selected_img;
      return { ...prevValue };
    });
  }

  function outlet_value_on_change(name: any, value: any) {
    // validate(name, value);
    SetOutletData((oldValues) => ({
      ...oldValues,
      [name]: value,
    }));
  }

  async function add_user() {
    if (outlet_data.cat_name !== "" && outlet_data.selected_roles !== "") {
      let post_value = {
        post: {
          name: outlet_data.cat_name,
          sector: outlet_data.selected_roles?.value,
        },
      };
      if (outlet_data.image_url != "") {
        post_value.post.image_url = outlet_data.image_url;
      }
      if (id_value.cat_id) {
        post_value.post.parent = id_value.cat_id;
      }

      let data_res = await api("/orders/create_category", post_value);
      if (data_res.status_code == 201) {
  await toast.success("Category Created")

  setTimeout(() => {
    navigate("/categories");
    SetOutletData(outlet_initial_value);
  }, 500); // 500ms delay
      

      }
    } else {
      SetUserErrors(true);
    }
  }

  return (
    <div className="">
      {/* Header */}

    
      <div className="head-bar">
        <div className="d-flex align-items-center">
          <Link to={"/categories"}>
            <Ionicons name="arrow-back-outline" size={20} color="black" />
          </Link>
          <h5 className="ms-2  mb-0">Add Categories</h5>
        </div>
      </div>
      <Toaster />
      <div className="px-2 sm-mt-3 sm-pt-4">
        <div className="border-top fixed-sub-header">
          <div className="mt-3 px-md-3 px-sm-0 mb-5">
            <div className="card p-4">
              <div className="row">
                <div className="col-12 col-md-6 mt-2">
                  <label htmlFor="userName" className="form-label">
                    Category Name
                    <span className="text-red fw-bold ps-1 small">*</span>
                  </label>
                  <div>
                    <input
                      type="text"
                      className="form-control"
                      id="userName"
                      value={outlet_data.cat_name}
                      placeholder="Category Name"
                      onChange={(e) => {
                        outlet_value_on_change("cat_name", e.target.value);
                      }}
                    />

                    {user_errors ? (
                      <small className="text-red">* Required fields</small>
                    ) : null}
                  </div>
                </div>
                <div className="col-12 col-md-6 mt-2">

     

                  <label className="form-label">
                    Sector
                    <span className="text-red fw-bold ps-1 small">*</span>
                  </label>
                  <Select
                    components={animatedComponents}
                    className="basic-multi-select"
                    classNamePrefix="select"
                    placeholder="Select Sector"
                    Group
                    value={outlet_data.selected_roles}
                    options={user_role_options}
                    onChange={(e, action) => {
                      SetOutletData((oldValues) => ({
                        ...oldValues,
                        selected_roles: e,
                      }));
                      outlet_value_on_change("role", e);
                      SetUserErrors(false);
                    }}
                    // isDisabled={props.type == "view" ? true : false}
                    
                    isDisabled={id_value.sec_id==0?false:true }

                  />
                  {user_errors ? (
                    <small className="text-red">* Required fields</small>
                  ) : null}
                </div>
                <div className="col-12 col-md-6 mt-2">
                  <label htmlFor="userName" className="form-label">
                    Image
                    <span className="text-red fw-bold ps-1 small">*</span>
                  </label>
                  <div>
                    <input
                      type="text"
                      className="form-control"
                      value={outlet_data.image_url}
                      placeholder="image url"
                      onChange={(e) => {
                        outlet_value_on_change("image_url", e.target.value);
                      }}
                    />

                    {user_errors ? (
                      <small className="text-red">* Required fields</small>
                    ) : null}
                  </div>
                </div>
                <div className="col-12 col-md-6 mt-2">
                  {/* Image */}
                  <div>
                    {outlet_data.image ? (
                      <div className=" p-1">
                        <div className=" p-1">
                          <div className="card">
                            <img
                              src={outlet_data.image_url}
                              className="cover_img radius"
                            />
                          </div>
                        </div>

                        <div
                          className="center cursor p-2 bg-fff border mt-3 radius"
                          onClick={() => {
                            onImgSelect(outlet_data.image);
                          }}
                        >
                          <MaterialCommunityIcons
                            name="image-plus"
                            size={18}
                            color="gray"
                          />
                          <p className="ps-2 mb-0 text-darkgray fw-bold ">
                            Add / Select images
                          </p>
                        </div>
                      </div>
                    ) : (
                      <div
                        className="center cursor py-2"
                        onClick={() => {
                          onImgSelect(modal_image.selected_images);
                        }}
                      >
                        <div className="border radius cursor p-5 center cover_img">
                          <div className="d-flex align-items-center">
                            <MaterialCommunityIcons
                              name="image-plus"
                              size={22}
                              color="gray"
                            />
                            <p className="ps-2 mb-0 text-darkgray">
                              Add / Select Images
                            </p>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className="ms-auto mt-4">
                <button
                  type="button"
                  className="btn btn-prime btn-radius btn-sm px-3"
                  onClick={() => {
                    add_user();
                  }}
                >
                  Add
                </button>
              </div>
            </div>
          </div>

          {modal_image.is_open ? (
            <ModalAddGallery
              open={modal_image.is_open}
              type={modal_image.type}
              data={modal_image.data}
              selected={modal_image.selected_images}
              from={"create_outlet"}
              close={(data: any) => {
                SetModalImage((prevValue: any) => {
                  prevValue.is_open = false;
                  return { ...prevValue };
                });

                if (data.action == "save") {
                  SetOutletData((prevValue: any) => {
                    prevValue.image = data.value[0].url;
                    prevValue.image_url = data.value[0].full_url;
                    prevValue.image_id = data.value[0].id;

                    return { ...prevValue };
                  });
                }
              }}
            />
          ) : null}
        </div>
      </div>
    </div>
  );
}
