import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import bootstrap from "../../assets/libraries/bootstrap/js/bootstrap";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import { api } from "../../utils/Api";
import ModalAddGallery from "./ModalAddGallery";
import { MaterialCommunityIcons } from "@expo/vector-icons";
import { useNavigate } from "react-router-dom";
import toast, { Toaster } from "react-hot-toast";

const animatedComponents = makeAnimated();

const franchise_initial_value = {
  cat_id: "",
  par_id: "",
  selected_roles: [],
  slug: "",

  cat_name: "",
  image_url: "",
  image: "",
};

let myModal = {};

export default function EditCategory(props: any) {
  const [close_data, SetCloseData] = useState({
    action: "close",
  });
  const [id_value, SerIdValue] = useState(useParams());
  const [modal_image, SetModalImage] = useState({
    is_open: false,
    selected_images: [],
  });
  const [franchise_data, SetFranchiseData] = useState(franchise_initial_value);
  const [user_role_options, SetUserRoleOptions] = useState({});

  const [page_no, SetPageNo] = useState(1);

  const [timer, SetTimer] = useState(null);
  const WAIT_INTERVAL = 700;

  const [modal_type, SetModalType] = useState("");
  const [user_errors, SetUserErrors] = useState(false);
  const [slug_errors, SetSlugErrors] = useState(false);

  //FranchiseData End

  const navigate = useNavigate();

  useEffect(() => {
    get_roles();
    get_category();
  }, []);

  async function get_roles() {
    let data_res = await api("/orders/sectors");

    let role_set_value = [];
    data_res.response.sectors.map((role_item, role_index) => {
      role_set_value.push({
        label: role_item.name,
        value: role_item.id,
        //   type: "roles",
      });
    });
    SetUserRoleOptions(role_set_value);
  }

  function onImgSelect() {
    SetModalImage((prevValue: any) => {
      prevValue.is_open = !prevValue.is_open;

      return { ...prevValue };
    });
  }

  async function get_category() {
    let pass_data = {
      get: {
        id: id_value.cat_id,
      },
    };
    let data_res = await api("/orders/get_category", pass_data);

    let cat_data = data_res.response.category[0];

    let set_data = {
      cat_id: cat_data.id,

      par_id: cat_data.parent_id,
      selected_roles: {
        value: cat_data.sector_id,
        label: cat_data.sector_name,
      },
      slug: cat_data.slug,

      cat_name: cat_data.name,
      image_url: cat_data.image,
    };
    SetFranchiseData(set_data);
  }

  async function update_user() {
    if (
      franchise_data.cat_name !== "" &&
      franchise_data.selected_roles !== ""
    ) {
      let pass_data = {
        post: {
          name: franchise_data.cat_name,
          sector: franchise_data.selected_roles?.value,
          category_id: franchise_data.cat_id,
          parent: franchise_data.par_id,
          slug: franchise_data.slug,
        },
      };
      if (franchise_data.image_url != "") {
        pass_data.post.image_url = franchise_data.image_url;
      }

      let data_res = await api("/orders/edit_category", pass_data);
      if (data_res.status_code == 200) {
        toast.success("Category Edited")

        
  setTimeout(() => {
    navigate("/categories");
    SetFranchiseData(franchise_initial_value);
  }, 500); // 500ms delay
      
    
      }
    }
  }

  function outlet_value_on_change(name: any, value: any) {
    SetFranchiseData((oldValues) => ({
      ...oldValues,
      [name]: value,
    }));
  }

  async function slug_value_on_change(name, value) {
    SetFranchiseData((oldValues) => ({
      ...oldValues,
      [name]: value,
    }));
    let pass_data = {
      post: {
        [name]: value,
      },
    };

    setTimeout(async () => {
      try {
        let response = await api("/orders/check_category_slug", pass_data);
        SetSlugErrors(response.response.exists);
      } catch (error) {
        console.error("API call error:", error);
      }
    }, 5000); // 5 seconds delay (5000 milliseconds)
  }

  return (
    <div className="border-top fixed-sub-header">
       <Toaster />
      <div className="mt-3 px-md-3 px-sm-0 mb-5">
        <div className="card p-4">
          <div className="row">
            <div className="col-12 col-md-6 mt-2">
              <label htmlFor="userName" className="form-label">
                Category Name
              </label>

              <input
                type="text"
                className="form-control"
                id="userName"
                value={franchise_data.cat_name}
                placeholder="Category Name"
                onChange={(e) => {
                  outlet_value_on_change("cat_name", e.target.value);
                }}
                readOnly={modal_type == "view" ? true : false}
              />
            </div>
            <div className="col-12 col-md-6 mt-2">
              <label className="form-label">Sector</label>
              <Select
                components={animatedComponents}
                className="basic-multi-select"
                classNamePrefix="select"
                placeholder="Select Sector"
                Group
                value={franchise_data.selected_roles}
                options={user_role_options}
                onChange={(e, action) => {
                  SetFranchiseData((oldValues) => ({
                    ...oldValues,
                    selected_roles: e,
                  }));
                  outlet_value_on_change("role", e);
                  SetUserErrors(false);
                }}
                // isDisabled={props.type == "view" ? true : false}
                isDisabled={ true} 

              />
              {user_errors ? (
                <small className="text-red">* Required fields</small>
              ) : null}
            </div>

            <div className="col-12 col-md-6 mt-2">
              <label className="form-label">Image</label>
              <input
                className="form-control"
                value={franchise_data.image_url}
                onChange={(e) => {
                  outlet_value_on_change("image_url", e.target.value);
                  // SetUserErrors(false);
                }}
              />
            </div>

            {/* Image */}
            <div className="col-12 col-md-6 mt-2">
              <div>
                {franchise_data.image_url ? (
                  <div className=" p-1">
                    <div className=" p-1">
                      <div className="card">
                        <img
                          src={franchise_data.image_url}
                          className="cover_img radius"
                        />
                      </div>
                    </div>

                    <div
                      className="center cursor p-2 bg-fff border mt-3 radius"
                      onClick={() => {
                        onImgSelect(franchise_data.image_url);
                      }}
                    >
                      <MaterialCommunityIcons
                        name="image-plus"
                        size={18}
                        color="gray"
                      />
                      <p className="ps-2 mb-0 text-darkgray fw-bold ">
                        Add / Select images
                      </p>
                    </div>
                  </div>
                ) : (
                  <div
                    className="center cursor py-2"
                    onClick={() => {
                      onImgSelect(modal_image.selected_images);
                    }}
                  >
                    <div className="border radius cursor p-5 center cover_img">
                      <div className="d-flex align-items-center">
                        <MaterialCommunityIcons
                          name="image-plus"
                          size={22}
                          color="gray"
                        />
                        <p className="ps-2 mb-0 text-darkgray">
                          Add / Select Images
                        </p>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>

            <div className="col-12 col-md-6 mt-2">
              <label htmlFor="userName" className="form-label">
                Slug
              </label>

              <input
                type="text"
                className="form-control"
                id="userName"
                value={franchise_data.slug}
                placeholder="Slug Name"
                onChange={(e) => {
                  slug_value_on_change("slug", e.target.value);
                }}
                readOnly={modal_type == "view" ? true : false}
              />
              {slug_errors ? (
                <small className="text-red">* Slug exists</small>
              ) : null}
            </div>
          </div>
          <div className="ms-auto mt-4">
            <button
              type="button"
              className="btn btn-primary btn-radius btn-sm px-3"
              onClick={() => {
                update_user();
              }}
            >
              Update
            </button>
          </div>
        </div>
      </div>

      {modal_image.is_open ? (
        <ModalAddGallery
          open={modal_image.is_open}
          type={modal_image.type}
          data={modal_image.data}
          selected={modal_image.selected_images}
          from={"create_outlet"}
          close={(data: any) => {
            SetModalImage((prevValue: any) => {
              prevValue.is_open = false;
              return { ...prevValue };
            });

            if (data.action == "save") {
              SetFranchiseData((prevValue: any) => {
                prevValue.image = data.value[0].url;
                prevValue.image_url = data.value[0].full_url;
                prevValue.image_id = data.value[0].id;

                return { ...prevValue };
              });
            }
          }}
        />
      ) : null}
    </div>
  );
}
