import React, { useState, useEffect, useContext } from "react";
import { Link, useParams } from "react-router-dom";
import { api } from "../../utils/Api";
import DataContext from "../../utils/Context/DataContext";
import ModalCreateCategory from "./ModalCreateCategories";
import ModalDeleteCategories from "./ModalDeleteCategories";
import ModalEditCategory from "./ModalEditCategory";
import SwipeableBottomSheet from "@geomatico/react-swipeable-bottom-sheet";
import toast, { Toaster } from "react-hot-toast";
import { Entypo, MaterialIcons } from "@expo/vector-icons";
import ModalDelete from "../../components/ModalDelete/ModalDelete";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import {
  Ionicons,
  MaterialCommunityIcons,
  FontAwesome,
} from "@expo/vector-icons";
let width = innerWidth;

export default function Categories(props: any) {
  const [categories, setCategories] = useState([]);
  const [org_category_data, SetOrgCategoryData] = useState([])


  const [edit_user_modal, SetEditUserModal] = useState({
    is_open: false,
    data: {},

    type: "edit",
  });
  const [category_modal, SetCategoryModal] = useState({
    is_open: false,
    type: "add",
    data: {},
  });
  const [cat_search_data, SetCatSearchData] = useState('')

  const [modal_delete, SetModalDelete] = useState({
    is_open: false,
    delete_type: "single",
    data: {
      title: "",
      body: "",
    },
    have_option: false,
    options: {},
  });
  const [openModal, SetopenModal] = useState({
    is_open: false,
    data: {},
  });

  const [action_sheet_open, SetActionSheetOpen] = useState({
    is_open: false,
    data: {},
  });
  const [visible_content, SetVisibleContent] = useState(false);

  useEffect(() => {
    get_categories();
  }, []);

  useEffect(() => {
    if (cat_search_data != '') {
      const filter_array = org_category_data.filter((ele) =>
        ele.name.toLowerCase().match(cat_search_data.toLowerCase()),
      )
      setCategories(filter_array)
    } else {
      setCategories(org_category_data)
    }
  }, [cat_search_data])

  const RenderCategories = (props: any) => {
    const [is_open, SetIsOpen] = useState(false);
    return props.categoryArray.map((category: any, i: any) => (
      <>
        <tr key={i}>
          <td>
            {/* {props.level != "child" ? ( */}
              <img
                src={
                  category.image != null
                    ? category.image+"?width=100"
                    : require("../../assets/img/folders.png?width=100")
                }
                
                className={props.level == "child" ? "cat-child category-img" : "category-img"}
              
              />
            {/* ) : null} */}
          </td>
          <td>
            <div
              className={props.level == "child" ? "cat-child" : ""}
              onClick={() => {
                SetIsOpen((prevalue) => {
                  prevalue = !prevalue;
                  return prevalue;
                });
              }}
            >
              <p
                className={
                  category.exists
                    ? "text-gray mb-0 fs-6 fw-bolder"
                    : "fs-6 text-dark mb-0"
                }
              >
                {category.name} #{category.id}
              </p>
              <p className="small pt-1 text-gray">{category.slug}</p>
              <div>{category.sector_name}
              </div>
            </div>
          </td>
          {/* arrow */}
          <td>
            {category.children && category.children.length > 0 ? (
              <div
                onClick={() => {

                
                  SetIsOpen((prevalue) => {
                    prevalue = !prevalue;
                    return prevalue;
                  });
                }}
              >
                {is_open ? (
                  <MaterialIcons
                    name="keyboard-arrow-down"
                    size={24}
                    color="black"
                  />
                ) : (
                  <MaterialIcons
                    name="keyboard-arrow-up"
                    size={24}
                    color="black"
                  />
                )}
              </div>
            ) : null}
          </td>
          {/* button 3 dor */}
          <td>
            {width > 768 ? (
             
              
              <div className="dropdown">
                <button
                  className="btn btn-white dropdown-toggle d-flex align-items-cetner"
                  type="button"
                  id="dropdownMenuButton1"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  <Entypo name="dots-three-vertical" size={14} color="#333" />
                </button>
                <ul
                  className="dropdown-menu"
                  aria-labelledby="dropdownMenuButton1"
                >
                  <li>
                    <a className="dropdown-item" href="#">
                      <div className="cursor">
                       
                        <Link
                          to={"/categories/add_category/" + category.id +"/" +category.sector_id}
                          className=" d-flex align-items-center"
                        >
                          <MaterialIcons name="add" size={18} color="#333" />
                          <p className="mb-0 p-2">Add</p>
                        </Link>
                      </div>
                    </a>
                  </li>
                  <li>
                    <a className="dropdown-item" href="#">
                      <div className="cursor d-flex align-items-center">
                        <Link
                          to={"/categories/edit_category/" + category.id}
                          className=" d-flex align-items-center"
                        >
                          <MaterialIcons name="edit" size={18} color="#333" />
                          <p className="mb-0 p-2">Edit</p>
                        </Link>
                      </div>
                    </a>
                  </li>
                  <li>
                    <a className="dropdown-item" href="#">
                      <div
                        className="cursor d-flex align-items-center"
                        onClick={() => {
                          SetopenModal({
                            is_open: true,
                            data: {
                              id: category.id,
                            },
                          });
                        }}
                      >
                        <MaterialIcons
                          name="delete-outline"
                          size={18}
                          color="#333"
                        />
                        <p className="mb-0 p-2">Delete</p>
                      </div>
                    </a>
                  </li>
                  <li>
                    <a className="dropdown-item" href="#">
                      <div
                        className="cursor d-flex align-items-center"
                        onClick={(event) => {
                          let set_data = {
                            value: category,
                          };
                          SetEditUserModal({
                            is_open: true,
                            type: "edit",
                            data: set_data,
                          });
                        }}
                      >
                        <MaterialIcons name="link" size={18} color="#333" />
                        <p className="mb-0 p-2">Link a Parent</p>
                      </div>
                    </a>
                  </li>
                </ul>
              </div>
            
            ) : (
              <button
                className="btn btn-white d-flex align-items-cetner"
                type="button"
                onClick={() => {
                  SetActionSheetOpen({
                    is_open: true,
                    data: category,
                  });
                }}
              >
                <Entypo name="dots-three-vertical" size={14} color="#333" />
              </button>
            )}
          </td>
        </tr>
        {is_open
          ? category.children &&
            category.children.length > 0 && (
              <RenderCategories
                key={i}
                categoryArray={category.children}
                level={"child"}
              />
            )
          : null}
      </>
    ));
  };

  async function get_categories() {
    let response = await api("/orders/categories");
    if (response.status_code == 200) {
      setCategories(response.response.categories);
      SetOrgCategoryData(response.response.categories)

      SetVisibleContent(true);

      if (response.response.hasOwnProperty("data")) {
      }
    }
  }

  return (
    <div className="">
      {/* Header */}
        <Toaster />
      <div className="head-bar row">
        <div className="col-lg-6">
          <h5 className="  mb-2">Categories</h5>
        </div>
        <div className="col-lg-6 pe-2 text-end d-flex">
        <div className="input-icons form-group p-2 me-2">
                <input
                  type="text"
                  className="form-control search-bar p-0"
                  placeholder="Search here..."
                  value={cat_search_data}
                  onChange={(e) => {
                    SetCatSearchData(e.target.value)
                  }}
                />
                <div className="cursor px-2 border-left">
                  <Ionicons name="md-search" size={16} color="#ccc" />
                </div>
              </div>
          <Link
            to={"/categories/add_category/0/0"}
            className="btn btn-prime btn-radius btn-sm px-3 ms-auto"
          >
            Add
          </Link>
        </div>
      </div>

      <div className="px-2 sm-mt-3 sm-pt-4">
        <div className="border-top fixed-sub-header">
          <div className="mt-3 px-md-3 px-sm-0 mb-5">
            {visible_content ? (
              <div className="card p-2">
                <div className="">
                  {/* {categories.length != 0 ? ( */}

                  <table className="table">
                    {/* <tbody>{renderCategories(categories, "parent")}</tbody> */}
                    <tbody>
                      <RenderCategories
                        categoryArray={categories}
                        level={"parent"}
                      />
                    </tbody>
                  </table>
                </div>
              </div>
            ) : (
              <div className="card p-2">
                <div className="">
                  <table className="table">
                    <tbody>
                      {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((ele) => (
                        <tr>
                          <td>
                            <Skeleton height={25} width={25} />
                          </td>
                          <td>
                            <Skeleton height={10} width={20} />
                          </td>
                          <td>
                            <Skeleton height={10} width={90} count={2} />
                          </td>
                          <td>
                            <Skeleton height={15} width={20} />
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>

      <ModalDelete
        open={modal_delete.is_open}
        data={modal_delete.data}
        delete_type={modal_delete.delete_type}
        have_option={modal_delete.have_option}
        options={modal_delete.options}
        close={(data: any) => {
          SetModalDelete((prevValue: any) => {
            prevValue.is_open = false;
            return { ...prevValue };
          });

          if (data.action == "save") {
          }
        }}
      />

      <ModalCreateCategory
        open={category_modal.is_open}
        data={category_modal.data}
        type={category_modal.type}
        close={(data) => {
          SetCategoryModal({
            is_open: false,
            type: "add",
            data: {},
          });
          if (data.action == "save") {
            get_categories();
          }
          if (data.action == "edit") {
            setCategories((prevValue) => {
              prevValue[data.index] = data.value;
              return [...prevValue];
            });
          }
        }}
      />

      <ModalDeleteCategories
        open={openModal.is_open}
        data={openModal.data}
        close={(data: any) => {
          SetopenModal((prevValue: any) => {
            prevValue.is_open = false;
            return { ...prevValue };
          });
          if (data.action == "save") {
            toast.success("Category Deleted")
            get_categories();
          }
        }}
      />

      <ModalEditCategory
        open={edit_user_modal.is_open}
        data={edit_user_modal.data}
        type={edit_user_modal.type}
        close={(data: any) => {
          SetEditUserModal({
            is_open: false,
            data: {},
            type: "",
            index: "",
          });
          if (data.action == "edit") {
            get_categories();
          }
        }}
      />

      {/* swipe sheet code */}

      {width < 768 ? (
        <SwipeableBottomSheet
          overflowHeight={0}
          style={{ zIndex: 1300 }}
          open={action_sheet_open.is_open}
          onClose={() => {
            SetActionSheetOpen({
              is_open: false,
              data: "",
            });
          }}
          onChange={() => {
            SetActionSheetOpen({
              is_open: false,
              data: "",
            });
          }}
        >
          <div className="bg-fff pb-3">
            <div className="bg-fff border-top-radius p-3 pb-1 center w-100">
              <div className="bottom-sheet-hr "></div>
            </div>

            <div className="mb-5">
              <div>
                <a className="dropdown-item" href="#">
                  <div className="cursor">
                    <Link
                      to={
                        "/categories/add_category/" + action_sheet_open.data.id
                      }
                      className=" d-flex align-items-center"
                    >
                      <MaterialIcons name="add" size={18} color="#333" />
                      <p className="mb-0 p-2">Add</p>
                    </Link>
                  </div>
                </a>
              </div>
              <div>
                <a className="dropdown-item" href="#">
                  <div
                    className="cursor d-flex align-items-center"
                    onClick={(event) => {
                      let set_data = {
                        value: action_sheet_open.data,
                      };
                      SetEditUserModal({
                        is_open: true,
                        type: "edit",
                        data: set_data,
                      });
                      SetActionSheetOpen({
                        is_open: false,
                        data: "",
                      });
                    }}
                  >
                    <Link
                      to={
                        "/categories/edit_category/" + action_sheet_open.data.id
                      }
                      className=" d-flex align-items-center"
                    >
                      <MaterialIcons name="edit" size={18} color="#333" />
                      <p className="mb-0 p-2">Edit</p>
                    </Link>
                  </div>
                </a>
              </div>
              <div>
                <a className="dropdown-item" href="#">
                  <div
                    className="cursor d-flex align-items-center"
                    onClick={() => {
                      SetopenModal({
                        is_open: true,
                        data: {
                          id: action_sheet_open.data.id,
                        },
                      });
                      SetActionSheetOpen({
                        is_open: false,
                        data: "",
                      });
                    }}
                  >
                    <MaterialIcons
                      name="delete-outline"
                      size={18}
                      color="#333"
                    />
                    <p className="mb-0 p-2">Delete</p>
                  </div>
                </a>
              </div>
              <div>
                <a className="dropdown-item" href="#">
                  <div
                    className="cursor d-flex align-items-center"
                    onClick={(event) => {
                      let set_data = {
                        value: action_sheet_open.data,
                      };
                      SetEditUserModal({
                        is_open: true,
                        type: "edit",
                        data: set_data,
                      });
                      SetActionSheetOpen({
                        is_open: false,
                        data: "",
                      });
                    }}
                  >
                    <MaterialIcons name="link" size={18} color="#333" />
                    <p className="mb-0 p-2">Link a Parent</p>
                  </div>
                </a>
              </div>
            </div>
          </div>
        </SwipeableBottomSheet>
      ) : null}
    </div>
  );
}
