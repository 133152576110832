import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import bootstrap from "../../assets/libraries/bootstrap/js/bootstrap";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import { api } from "../../utils/Api";
// import { omit } from "lodash";
// import ModalAddGallery from "./ModalAddGallery";
import { MaterialCommunityIcons } from "@expo/vector-icons";

const animatedComponents = makeAnimated();

const outlet_initial_value = {
  selected_user: {
    id: "",
    name: "",
  },
  user_id: "",
  selected_roles: [],
  roles: { roles: [], custom_roles: [] },
  address: "",
  mobile: "",
  whatsapp: "",
  email: "",
  cat_name: "",
  image_url: "",
};

let myModal :any;

export default function ModalCreateCategory(props: any) {
  const [close_data, SetCloseData] = useState({
    action: "close",
  });
  const [id_value, SerIdValue] = useState(useParams());
  const [slug_errors, SetSlugErrors] = useState(false);
  const [slug, SetSlug] = useState("");


  const [outlet_data, SetOutletData] = useState(outlet_initial_value);
  const [user_role_options, SetUserRoleOptions] = useState([]);
  const [text, setText] = useState("");
  const [page_no, SetPageNo] = useState(1);
  const [user_data, SetUserData] = useState([]);
  const [next_page, SetNextPage] = useState(false);
  const [userOpen, setUserOpen] = useState(false);
  const [timer, SetTimer] = useState(null);
  const WAIT_INTERVAL = 700;
  const [modal_type, SetModalType] = useState("");
  const [user_errors, SetUserErrors] = useState(false);
  const [img_url, setImageUrl] = useState("");

  const [modal_image, SetModalImage] = useState({
    is_open: false,
    selected_images: [],
  });
  //OutletData End

  useEffect(() => {
    let myModal1: HTMLElement | null = document.getElementById("ModalUser");
    myModal1.addEventListener("hidden.bs.modal", function (event) {
      SetOutletData(outlet_initial_value);
      setText("");
      SetPageNo(1);
      props.close(close_data);
    });
  }, []);

  useEffect(() => {
    if (props.open) {

      console.log(props)
      setImageUrl(props.data.image)
      SetOutletData((oldValues) => ({
        ...oldValues,
        cat_name: props.data.cat_name,
      }));
      myModal = new bootstrap.Modal(document.getElementById("ModalUser"), {});
      myModal.show();
      SetModalType(props.type);
      get_roles();
      if(props.type == "edit"){
        SetSlug(props.data.slug)
      }
      //   get_users(page_no);
      // if (props.type == "edit" || props.type == "view") {
      //   let set_data = {
      //     // user_id: props.data.value.user_id,
      //     user_id: props.data.value.id,

      //     selected_user: {
      //       id: props.data.value.user_id,
      //       name: props.data.value.user,
      //     },
      //     selected_roles: [],
      //     roles: { roles: [], custom_roles: [] },
      //     address:
      //       props.data.value.hasOwnProperty("address") &&
      //       props.data.value.address != null
      //         ? props.data.value.address
      //         : "",
      //     mobile:
      //       props.data.value.hasOwnProperty("mobile") &&
      //       props.data.value.mobile != null
      //         ? props.data.value.mobile
      //         : "",
      //     whatsapp:
      //       props.data.value.hasOwnProperty("whatsapp") &&
      //       props.data.value.whatsapp != null
      //         ? props.data.value.whatsapp
      //         : "",
      //     email:
      //       props.data.value.hasOwnProperty("email") &&
      //       props.data.value.email != null
      //         ? props.data.value.email
      //         : "",
      //   };

      //   SetOutletData(set_data);
      // }
    }
  }, [props.open,props.data]);

  async function slug_value_on_change(name, value) {
   SetSlug(value)
    let pass_data = {
      post: {
        [name]: value,
      },
    };

    setTimeout(async () => {
      try {
        let response = await api("/orders/check_category_slug", pass_data);
        SetSlugErrors(response.response.exists);
      } catch (error) {
        console.error("API call error:", error);
      }
    }, 5000); // 5 seconds delay (5000 milliseconds)
  }
  function onImgSelect() {
    SetModalImage((prevValue: any) => {
      prevValue.is_open = !prevValue.is_open;
      // prevValue.selected_images = data.selected_img;
      return { ...prevValue };
    });
  }
  // async function get_roles() {
  //   let data_res = await api("/orders/sectors");

  //   let role_set_value = [];
  //   data_res.response.sectors.map((role_item, role_index) => {
  //     role_set_value.push({
  //       label: role_item.name,
  //       value: role_item.id,
  //       //   type: "roles",
  //     });
  //   });
  //   SetUserRoleOptions(role_set_value);
  // }

  
  async function get_roles() {
    console.log(props.data.sector_id)
    let data_res = await api("/orders/sectors");
    let role_set_value = [];

    if(props.data.sector_id !=0){
    let desiredSector = data_res.response.sectors.find(sector => sector.id == props.data.sector_id);
     let pre_roles = {
      value: desiredSector.id,
      label: desiredSector.name
  };
  
  SetOutletData((oldValues) => ({
    ...oldValues,
    selected_roles: pre_roles,
  }));
}
  console.log(data_res.response.sectors)
    data_res.response.sectors.map((role_item, role_index) => {
      if(role_item.id != 4){
      role_set_value.push({
        label: role_item.name,
        value: role_item.id,
        //   type: "roles",
      });
    }
    });
    console.log(role_set_value)
    SetUserRoleOptions(role_set_value);
  }

  function onTextChanged(e: any) {
    clearTimeout(timer);
    const value = e.target.value;
    setText(value);

    if (value == "") {
      let page_no_value = 1;
      SetPageNo(page_no_value);
      get_users(page_no_value);
    } else {
      SetTimer(
        setTimeout(() => {
          get_users(1, value);
        }, WAIT_INTERVAL)
      );
    }
  }

  async function load_more(search_value = "") {
    let page_no_value = page_no + 1;
    let pass_data = {};

    SetPageNo(page_no_value);
    if (search_value != "") {
      pass_data = {
        get: {
          brand: id_value.brand_id,
          page: page_no_value,
          search: search_value,
        },
      };
    } else {
      pass_data = {
        get: {
          brand: id_value.brand_id,
          page: page_no_value,
        },
      };
    }
    let response = await api("/brand/get_users_add_brand", pass_data);

    SetUserData((prevValue) => {
      response.response.data.map((item) => {
        prevValue.push(item);
      });
      return [...prevValue];
    });
    SetNextPage(response.response.next_page);
  }

  function outlet_value_on_change(name: any, value: any) {
    // validate(name, value);
    SetOutletData((oldValues) => ({
      ...oldValues,
      [name]: value,
    }));
  }

  async function add_user() {
    if (outlet_data.cat_name !== "" && outlet_data.selected_roles !== "") {
      let post_value = {
        post: {
          name: outlet_data.cat_name,
          sector: outlet_data.selected_roles?.value,
        },
      };
      if (outlet_data.image_url != "") {
        post_value.post.image_url = outlet_data.image_url;
      }
      if (props.data.pass_data.id) {
        post_value.post.parent = props.data.pass_data.id;
      }

      let data_res = await api("/orders/create_category", post_value);

      if (data_res.status_code == 200) {
        let item = close_data;
        item.action = "save";
        // item.value = data_res.response.users[0];

        SetCloseData(item);
        SetOutletData(outlet_initial_value);
        myModal.hide();
      }
    } else {
      SetUserErrors(true);
    }
  }

  async function update_user() {
    let post_value = {
      user_id: outlet_data.user_id,
      roles: {},
      address: outlet_data.address,
      mobile: outlet_data.mobile,
      whatsapp: outlet_data.whatsapp,
      email: outlet_data.email,
    };
    let roles_value = {
      roles: [],
      custom_roles: [],
    };
    outlet_data.selected_roles.map((item, index) => {
      if (item.type == "roles") {
        roles_value.roles.push(item.value);
      } else {
        roles_value.custom_roles.push(item.value);
      }
    });
    post_value.roles = roles_value;
    let pass_data = {
      get: {
        brand: id_value.brand_id,
        outlet: id_value.outlet_id,
      },
      post: post_value,
    };

    let data_res = await api("/brand/edit_brand_user", pass_data);
    if (data_res.status_code == 200) {
      let item = close_data;
      item.action = "edit";
      item.value = data_res.response.brand_users[0];
      item.index = props.data.index;

      SetCloseData(item);
      SetOutletData(outlet_initial_value);
      myModal.hide();
    }
  }

  return (
    <div
      className="modal fade"
      id="ModalUser"
      tabIndex={-1}
      aria-labelledby="ModalLabel"
      aria-hidden="true"
      // data-bs-backdrop="static"
    >
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="  modal-title" id="ModalLabel">
              {userOpen ? (
                <div className="d-flex">
                  <img
                    // src={require("../../../assets/icon/svg/arrow-left.svg")}
                    className="cursor action-img px-2"
                    onClick={() => {
                      setUserOpen(false);
                    }}
                  />
                  Select User
                </div>
              ) : modal_type == "add" ? (
                "Add Category"
              ) : modal_type == "edit" ? (
                "Edit Category"
              ) : (
                "View Category"
              )}
            </h5>
            <button
              type="button"
              className="btn-close"
              aria-label="Close"
              onClick={() => {
                let item = close_data;
                item.action = "close";
                SetCloseData(item);
                myModal.hide();
              }}
            ></button>
          </div>
          <div className="modal-body">

            {console.log(props)}
          <div className="px-2 sm-mt-3 sm-pt-4">
        <div className="border-top fixed-sub-header">
          {/* <div className="mt-3 px-md-3 px-sm-0 mb-5"> */}
            <div className="card p-4">
              <div className="row">
                <div className="col-12 col-md-6 mt-2">
                  <label htmlFor="userName" className="form-label">
                    Category Name
                    <span className="text-red fw-bold ps-1 small">*</span>
                  </label>
                  <div>
                    <input
                      type="text"
                      className="form-control"
                      id="userName"
                      value={outlet_data.cat_name}
                      placeholder="Category Name"
                      onChange={(e) => {
                        outlet_value_on_change("cat_name", e.target.value);
                      }}
                    />

                    {user_errors ? (
                      <small className="text-red">* Required fields</small>
                    ) : null}
                  </div>
                </div>
                <div className="col-12 col-md-6 mt-2">

     

                  <label className="form-label">
                    Sector
                    {console.log(user_role_options)}
                    <span className="text-red fw-bold ps-1 small">*</span>
                  </label>
                  <Select
                    components={animatedComponents}
                    className="basic-multi-select"
                    classNamePrefix="select"
                    placeholder="Select Sector"
                    Group
                    value={outlet_data.selected_roles}
                    options={user_role_options}
                    onChange={(e, action) => {
                      SetOutletData((oldValues) => ({
                        ...oldValues,
                        selected_roles: e,
                      }));
                      outlet_value_on_change("role", e);
                      SetUserErrors(false);
                    }}
                    // isDisabled={props.type == "view" ? true : false}
                   
                    isDisabled={(props.data.level == "parent" )?false:true }

                  />
                  {user_errors ? (
                    <small className="text-red">* Required fields</small>
                  ) : null}
                </div>
                <div className="col-12 col-md-6 mt-2">
                  <label htmlFor="userName" className="form-label">
                    Image
                    <span className="text-red fw-bold ps-1 small">*</span>
                  </label>
                  <div>
                    <input
                      type="text"
                      className="form-control"
                      value={props.data.image}
                      placeholder="image url"
                      onChange={(e) => {
                        outlet_value_on_change("image_url", e.target.value);
                      }}
                    />

                    {user_errors ? (
                      <small className="text-red">* Required fields</small>
                    ) : null}
                  </div>
                </div>
                <div className="col-12 col-md-6 mt-2">
                  {/* Image */}
                  <div>
                    {console.log(props)}
                    {props?.data?.image !=""? (
                      <div className=" p-1">

                        <div className=" p-1">
                          <div className="card">
                            <img
                              src={props?.data?.image}
                              className="cover_img radius"
                            />
                          </div>
                        </div>

                        <div
                          className="center cursor p-2 bg-fff border mt-3 radius"
                          onClick={() => {
                            // onImgSelect(outlet_data.image);
                            props.sendMessageToParent("brand")
                          }}
                        >
                          <MaterialCommunityIcons
                            name="image-plus"
                            size={18}
                            color="gray"
                          />
                          <p className="ps-2 mb-0 text-darkgray fw-bold ">
                            Add / Select images
                          </p>
                        </div>
                      </div>
                    ) : (
                      <div
                        className="center cursor py-2"
                        onClick={() => {
                          // onImgSelect(modal_image.selected_images);
                          props.sendMessageToParent("brand")

                        }}
                      >
                        <div className="border radius cursor p-5 center cover_img">
                          <div className="d-flex align-items-center">
                            <MaterialCommunityIcons
                              name="image-plus"
                              size={22}
                              color="gray"
                            />
                            <p className="ps-2 mb-0 text-darkgray">
                              Add / Select Images
                            </p>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
                {props.type == "edit" &&
                <div className="col-12 col-md-6 mt-2">
              <label htmlFor="userName" className="form-label">
                Slug
              </label>

              <input
                type="text"
                className="form-control"
                id="userName"
                value={slug}
                placeholder="Slug Name"
                onChange={(e) => {
                  slug_value_on_change("slug", e.target.value);
                }}
                readOnly={modal_type == "view" ? true : false}
              />
              {slug_errors ? (
                <small className="text-red">* Slug exists</small>
              ) : null}
            </div>
}
              </div>
              {/* <div className="ms-auto mt-4">
                <button
                  type="button"
                  className="btn btn-prime btn-radius btn-sm px-3"
                  onClick={() => {
                    add_user();
                  }}
                >
                  Add
                </button>
              </div> */}
            </div>
          {/* </div>
          
          
          */}

          {/* {modal_image.is_open ? (
            <ModalAddGallery
              open={modal_image.is_open}
              type={modal_image.type}
              data={modal_image.data}
              selected={modal_image.selected_images}
              from={"create_outlet"}
              close={(data: any) => {
                SetModalImage((prevValue: any) => {
                  prevValue.is_open = false;
                  return { ...prevValue };
                });

                if (data.action == "save") {
                  SetOutletData((prevValue: any) => {
                    prevValue.image = data.value[0].url;
                    prevValue.image_url = data.value[0].full_url;
                    prevValue.image_id = data.value[0].id;

                    return { ...prevValue };
                  });
                }
              }}
            />
          ) : null} */}
        </div>
      </div>
          </div>
          <div className="modal-footer">
            {userOpen ? (
              <div>
                <button
                  type="button"
                  className="btn btn-light-outline"
                  onClick={() => {
                    setUserOpen(false);
                  }}
                >
                  Cancel
                </button>
              </div>
            ) : (
              <div>
                {modal_type == "add" ? (
                  <button
                    type="button"
                    className="btn btn-light-outline"
                    onClick={() => {
                      // add_user();

                      let item: any = {
                        action: "close",
                        value: "",
                        index: "",
                        demo_order: false
                      };

                     if( outlet_data.cat_name !=""){
                      let data: any = {
                        name: outlet_data.cat_name,
                        sector: outlet_data.selected_roles?.value,
                        id :props.data.id,
                      };
                      item.action = "save";
                      item.type ="add";

                      item.data = data;
                      props.close(item);
      
                      myModal.hide();
                    }
                    }}
                  >
                    Add
                  </button>
                ) : modal_type == "edit" ? (
                  <button
                    type="button"
                    className="btn btn-light-outline"
                   onClick={() => {
                      // add_user();

                      let item: any = {
                        action: "close",
                        value: "",
                        index: "",
                        demo_order: false
                      };

                     if( outlet_data.cat_name !="" ){
                      let data: any = {
                        name: outlet_data.cat_name,
                        sector: outlet_data.selected_roles?.value,
                        parent :props.data.parent_id,
                        id :props.data.id,
                        slug:slug,
                        image:img_url

                      };
                      item.action = "save";
                      item.type ="edit";
                      item.data = data;
                      props.close(item);
      
                      myModal.hide();
                    }
                    }}
                  >
                    Update
                  </button>
                ) : modal_type == "view" ? (
                  <button
                    type="button"
                    className="btn btn-light-outline"
                    onClick={(event) => {
                      SetCloseData(event);
                      myModal.hide();
                    }}
                  >
                    Close
                  </button>
                ) : null}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
