import React ,{useState,useEffect} from 'react'
import { useOutletContext } from "react-router-dom";
import Skeleton from "react-loading-skeleton";
import { api } from "../../utils/Api";
import ModalTime from './ModalTime';
import ReactApexChart from 'react-apexcharts';
import moment from "moment";

// import { LazyLoader, ImageLazyLoader } from "../../utils/Loader/index";


function OutletTotalOrderStatistics() {

  const [overview_is_loading, SetOverviewIsLoading] = useState(true);
  const [statistics_data, SetStatisticsData]: any = useState([]);
  const [avg_value, SetAvgValue]: any = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [statisticsView, SetStatisticsView]: any = useState('week');
  const [statisticsViewFormatted, SetStatisticsViewFormatted]: any = useState('week');
  const OutletContext = useOutletContext();
  const dropdownOptions = [
    { label: "Highest Orders", value: 1 },
    { label: "Lowest Orders", value: 2 },
    { label: "Ascending Brand Name", value: 3 },
    { label: "Descending Brand Name", value: 4 },
    { label: "Ascending Outlet Name", value: 5 },
    { label: "Descending Brand Name", value: 6 },
  ];
  const [weekData, setWeekData] = useState<any[]>([]);
  const [time_modal, setTimeModal] = useState({
    is_open: false,
    data: {},
  });
    const [barChartData, setBarChartData] = useState<any>({
      series: [{
        name: 'Total Orders',
        data: []
      }],
      options: {
        chart: {
          type: 'bar',
          height: 400,
        },
       
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: '15%',
          }
        },
        xaxis: {
          categories: [],
        },
        yaxis: {
          title: {
            text: 'Number of Order Users'
          }
        },
        dataLabels: {
          enabled: false
        },
        colors: ['#00E396'], // Customize the color of bars
        // title: {
        //   text: 'First Order Users by Range',
        //   align: 'center'
        // }
      }
    });
  const now = new Date();

  // const startOfToday = new Date(now.getFullYear(), now.getMonth(), now.getDate(), 0, 0, 0);
  // const endOfToday = new Date(now.getFullYear(), now.getMonth(), now.getDate(), 23, 59, 59);
  // const [startDateShow, setStartDateShow] = useState(getFormattedDateTime(startOfToday));
  // const [endDateShow, setEndDateShow] = useState(getFormattedDateTime(endOfToday));
  // const [startDate, setStartDate] = useState(convertDateTime(getFormattedDateTime(startOfToday)));
  // const [endDate, setEndDate] = useState(convertDateTime(getFormattedDateTime(endOfToday)));
  const [startDateShow, setStartDateShow] = useState("07-06-2023");
  const [endDateShow, setEndDateShow] = useState("17-02-2025");

  const [startDate, setStartDate] = useState("07-06-2023");
  const [endDate, setEndDate] = useState("17-02-2025");
  const [width, SetWidth] = useState(innerWidth);


  useEffect(() => {
    fetchData();
  }, [statisticsView]);

  const fetchData = async () => {
    console.log("Running cache update...");
  
    const cacheResponse: any = await api("/marketing/statistics_total_1st_order_users_run_cache");
    if (cacheResponse.status_code !== 200) {
      console.error("Cache update failed:", cacheResponse);
      return;
    }
  
    console.log("Cache update successful. Fetching statistics...");
    processStatisticsData();
  };
  
  const processStatisticsData = async () => {
    let newStartDate = startDate;
    let newEndDate = endDate;
    const now = new Date();
    let week = [];
  
    if (statisticsView === "today") {
      newStartDate = newEndDate = convertDateTime(getFormattedDateTime(now));
      SetStatisticsViewFormatted("Today");
    } else if (statisticsView === "yesterday") {
      const yesterday = new Date();
      yesterday.setDate(now.getDate() - 1);
      newStartDate = newEndDate = convertDateTime(getFormattedDateTime(yesterday));
      SetStatisticsViewFormatted("Yesterday");
    } else if (statisticsView === "lastweek") {
      week = getWeekDays(statisticsView);
      setWeekData(week);
      console.log(week);
      const lastWeekStart = new Date();
      lastWeekStart.setDate(now.getDate() - now.getDay() - 7);
      const lastWeekEnd = new Date();
      lastWeekEnd.setDate(now.getDate() - now.getDay() - 1);
  
      newStartDate = convertDateTime(getFormattedDateTime(lastWeekStart));
      newEndDate = convertDateTime(getFormattedDateTime(lastWeekEnd));
      SetStatisticsViewFormatted("Last Week");
    } else if (statisticsView === "week") {
      console.log("week");
      week = getWeekDays(statisticsView);
      setWeekData(week);
      const weekStart = new Date();
      weekStart.setDate(now.getDate() - now.getDay());
      const weekEnd = new Date(weekStart);
      weekEnd.setDate(weekStart.getDate() + 6);
      newStartDate = convertDateTime(getFormattedDateTime(weekStart));
      newEndDate = convertDateTime(getFormattedDateTime(weekEnd));
      SetStatisticsViewFormatted("This Week");
    } else if (statisticsView === "thismonth") {
      week = getMonthDatesArray(statisticsView);
      const monthStart = new Date(now.getFullYear(), now.getMonth(), 1);
      newStartDate = convertDateTime(getFormattedDateTime(monthStart));
      newEndDate = convertDateTime(getFormattedDateTime(now));
      SetStatisticsViewFormatted("This Month");
    } else if (statisticsView === "lastmonth") {
      week = getMonthDatesArray(statisticsView);
      const lastMonthStart = new Date(now.getFullYear(), now.getMonth() - 1, 1);
      const lastMonthEnd = new Date(now.getFullYear(), now.getMonth(), 0);
      newStartDate = convertDateTime(getFormattedDateTime(lastMonthStart));
      newEndDate = convertDateTime(getFormattedDateTime(lastMonthEnd));
      SetStatisticsViewFormatted("Last Month");
    } else if (statisticsView === "thisyear") {
      console.log(statisticsView);
      week = getYearMonths(statisticsView);
      const yearStart = new Date(now.getFullYear(), 0, 1);
      const yearEnd = new Date(now.getFullYear(), 11, 31);
      newStartDate = convertDateTime(getFormattedDateTime(yearStart));
      newEndDate = convertDateTime(getFormattedDateTime(yearEnd));
      SetStatisticsViewFormatted("This Year");
    } else if (statisticsView === "lastyear") {
      week = getYearMonths(statisticsView);
      const lastYearStart = new Date(now.getFullYear() - 1, 0, 1);
      const lastYearEnd = new Date(now.getFullYear() - 1, 11, 31);
      newStartDate = convertDateTime(getFormattedDateTime(lastYearStart));
      newEndDate = convertDateTime(getFormattedDateTime(lastYearEnd));
      SetStatisticsViewFormatted("Last Year");
    } else if (statisticsView === "customdate") {
      SetStatisticsViewFormatted("Custom Date");
    } else if (statisticsView === "custommonth") {
      SetStatisticsViewFormatted("Custom Month");
    }
  
    // Update state with new values
    setStartDate(newStartDate);
    setEndDate(newEndDate);
    setStartDateShow(newStartDate);
    setEndDateShow(newEndDate);
  
    get_overview(week, statisticsView);
  };


  const getMonthWiseData = (startDateStr: string, endDateStr: string) => {
    const start = moment(startDateStr.trim(), "DD-MM-YYYY");
    const end = moment(endDateStr.trim(), "DD-MM-YYYY");
    const monthsArray = [];

    // First month: Start from given start date, end at last day of that month
    monthsArray.push({
        start: start.format("DD-MM-YYYY 00:00:00"),
        end: start.clone().endOf("month").format("DD-MM-YYYY 23:59:59"),
    });

    // Move to the next month
    start.add(1, "month").startOf("month");

    // Middle months: Full months between start and end date
    while (start.isBefore(end, "month")) {
        monthsArray.push({
            start: start.format("DD-MM-YYYY 00:00:00"),
            end: start.clone().endOf("month").format("DD-MM-YYYY 23:59:59"),
        });
        start.add(1, "month").startOf("month");
    }

    // Last month: Start from 1st, end at given end date
    monthsArray.push({
        start: start.format("DD-MM-YYYY 00:00:00"),
        end: end.format("DD-MM-YYYY 23:59:59"),
    });

    return monthsArray;
};

const getCustomDateRange = (startDateStr: string, endDateStr: string) => {
  const start = moment(startDateStr.trim(), "DD-MM-YYYY");
  const end = moment(endDateStr.trim(), "DD-MM-YYYY");
  const dateArray = [];

  while (start.isSameOrBefore(end, "day")) {
    dateArray.push({
      start: start.format("DD-MM-YYYY 00:00:00"),
      end: start.format("DD-MM-YYYY 23:59:59"),
    });
    start.add(1, "day"); // Move to the next day
  }

  return dateArray;
};



  const getWeekDays = (weekType: string) => {
    let startOfWeek;
    console.log(weekType)

    console.log(weekType == "lastweek")
    if (weekType == "lastweek") {
    console.log(weekType)

      startOfWeek = moment().subtract(1, "weeks").day(0);;
    } else {
      startOfWeek = moment().startOf("week");
    }

    return Array.from({ length: 7 }, (_, i) => {
      const date = startOfWeek.clone().add(i, "days");
      return {
        // day: date.format("dddd"), // "Sunday", "Monday", etc.
        start: date.format("DD-MM-YYYY 00:00:00"),
        end: date.format("DD-MM-YYYY 23:59:59"),
        // value: Math.floor(Math.random() * 100) // Random example data
      };
    });
  };

  const getYearMonths = (yearType: string) => {
    let startOfYear;
    console.log(yearType);
  
    if (yearType === "lastyear") {
      startOfYear = moment().subtract(1, "years").startOf("year"); // January 1st of Last Year
    } else {
      startOfYear = moment().startOf("year"); // January 1st of This Year
    }
  
    return Array.from({ length: 12 }, (_, i) => {
      const monthStart = startOfYear.clone().month(i).startOf("month"); // 1st day of the month
      const monthEnd = startOfYear.clone().month(i).endOf("month"); // Last day of the month
  
      return {
        start: monthStart.format("DD-MM-YYYY 00:00:00"),
        end: monthEnd.format("DD-MM-YYYY 23:59:59"),
      };
    });
  };

  const getMonthDatesArray = (monthType: string) => {
    let startOfMonth =
      monthType === "lastmonth"
        ? moment().subtract(1, "months").startOf("month") // Last month
        : moment().startOf("month"); // This month
  
    const daysInMonth = startOfMonth.daysInMonth(); // Get total days in the month
  
    return Array.from({ length: daysInMonth }, (_, dayIndex) => {
      const date = startOfMonth.clone().add(dayIndex, "days");
      return {
        start: date.format("DD-MM-YYYY 00:00:00"),
        end: date.format("DD-MM-YYYY 23:59:59"),
      };
    });
  };

  

  function getFormattedDateTime(date) {
    
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // getMonth() returns 0-11
    const day = String(date.getDate()).padStart(2, '0');
    // const hours = String(date.getHours()).padStart(2, '0');
    // const minutes = String(date.getMinutes()).padStart(2, '0');


    return `${year}-${month}-${day}`;
    // return `${year}-${month}-${day}T${hours}:${minutes}`;
  }
  function convertDateTime(dateTimeStr) {
    const date = new Date(dateTimeStr);
    
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0'); // getMonth() returns 0-11
    const year = date.getFullYear();
    
    // const hours = String(date.getHours()).padStart(2, '0');
    // const minutes = String(date.getMinutes()).padStart(2, '0');
    // const seconds = '00'; // Set seconds to 00
    
    // const formattedDateTime = `${day}-${month}-${year} ${hours}:${minutes}:${seconds}`;
    const formattedDateTime = `${day}-${month}-${year}`;

    return formattedDateTime;
  }

  

//   async function get_overview(get_data: any,type:any) {
// console.log(type == "week" );
// console.log( "week" );

//     SetOverviewIsLoading(true)
//      let pass_data: any
    
//         pass_data = {
    
//             post: {
//               date: get_data
//             }
//           };
   
//      let response: any = await api("/marketing/statistics_total_1st_order_users", pass_data);
//      console.log("/qr/overview response :", response.response);
//      if (response.status_code == 200) {
//        if (response.response.hasOwnProperty("statistics")) {
//         //  let statistics = response.response.statistics;
//          let statistics_data = response.response.statistics;
//          console.log(statistics_data);
//          SetStatisticsData(statistics_data);

//          const dateKey = `${get_data.startDate} 00:00:00 ${get_data.endDate} 23:59:59`;

//          const keysArray = get_data.map(item => `${item.start} ${item.end}`);

// console.log(keysArray);
//           // Get the value for the date range

//           const valuesArray = keysArray.map(key => statistics_data[key]);
//           // const valuesArray = [2,5,6,7,2,4,2];
//           const datesArray = keysArray.map(key => key.split(" ")[0]);

// console.log(datesArray);
//           console.log(valuesArray)


// console.log(valuesArray)

//         const order_series = [
//           statistics_data[dateKey],
         
//         ].filter(Boolean); // Filter out null/undefined values
//         let order_labels: string[];
//         if (type == "week" || type == "lastweek") {
//       console.log(type)

//           // order_labels = [
//           //   "Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"
//           // ];
//           const startOfWeek = type =="lastweek"
//           ? moment().subtract(1, "weeks").startOf("week") // Last week's Sunday
//           : moment().startOf("week"); // This week's Sunday
  
//       order_labels = Array.from({ length: 7 }, (_, i) => {
//           const day = startOfWeek.clone().add(i, "days"); // Get each day
//           return `${day.format("dddd")} (${day.format("DD-MM-YYYY")})`; 
//       });
//       } else if (type == "yesterday") {
//           order_labels = ["Yesterday"];
//       }else if (type == "thismonth" || type == "lastmonth") {
//         order_labels = datesArray;
//     }else if (type == "thisyear" || type == "lastyear") {
//         order_labels = [
//             "January", "February", "March", "April", "May", "June", 
//             "July", "August", "September", "October", "November", "December"
//         ];
//     }  if (type == "lastmonth") {
//       // Extract month names from keysArray
//       order_labels = keysArray.map(key => {
//           const date = moment(key.split(" ")[0], "YYYY-MM-DD"); // Convert to moment object
//           return date.format("MMMM"); // Get full month name
//       });
//   }else {
//           order_labels = [`${get_data.startDate} - ${get_data.endDate}`];
//       }
//       setBarChartData((prevState: any) => ({
//         ...prevState,
//         series: [
//           {
//             name: "Total Orders",
//             data: valuesArray, // Assign values correctly
//           },
//         ],
//         options: {
//           ...prevState.options,
//           xaxis: {
//             ...prevState.options.xaxis,
//             categories: order_labels, // Set correct labels
//           },
//           dataLabels: {
//             enabled: true, // ✅ Enable labels above bars
//             formatter: (val: number) => val.toString(), // Convert numbers to string for display
//             offsetY: -20, // ✅ Ensure labels are positioned above bars
//             style: {
//               fontSize: "12px",
//               fontWeight: "bold",
//               colors: ["#304758"], // Label color
//             },
//           },
//           plotOptions: {
//             bar: {
//               dataLabels: {
//                 position: "top", // ✅ Ensure labels appear at the top
//               },
//             },
//           },
//           chart: {
//             toolbar: {
//               show: false, // Hide unnecessary toolbar
//             },
//           },
//         },
//       }));
    
//        }

      
 
//        SetOverviewIsLoading(false);
//      }
//    }
async function get_overview(get_data: any, type: any) {
  console.log("Selected type:", type);

  SetOverviewIsLoading(true);

  const pass_data = {
      post: { date: get_data }
  };

  let response: any = await api("/marketing/statistics_total_1st_order_users", pass_data);
  console.log("/qr/overview response:", response.response);

  if (response.status_code !== 200 || !response.response.hasOwnProperty("statistics")) {
      SetOverviewIsLoading(false);
      return;
  }

  let statistics_data = response.response.statistics;
  console.log("Statistics Data:", statistics_data);

  const keysArray = get_data.map((item: any) => `${item.start} ${item.end}`);
  console.log("Keys Array:", keysArray);

  // Extract values based on keys
  const firstValuesArray = keysArray.map((key: string) => statistics_data["1st"][key] || 0);
  const secondValuesArray = keysArray.map((key: string) => statistics_data["2nd"][key] || 0);
  const thirdValuesArray = keysArray.map((key: string) => statistics_data["3rd"][key] || 0);
  const fourthValuesArray = keysArray.map((key: string) => statistics_data["4th"][key] || 0);
  const fifthValuesArray = keysArray.map((key: string) => statistics_data["5th"][key] || 0);


  // const firstValuesArray = [1,3,4,5,6,2,6];
  // const secondValuesArray = [1,3,4,5,6,2,6];
  // const thirdValuesArray = [1,3,4,5,6,2,6];
  // const fourthValuesArray = [1,3,4,5,6,2,6];

  const datesArray = keysArray.map((key: string) => key.split(" ")[0]);

  console.log("Dates Array:", datesArray);
  console.log("Dates Array:", keysArray);

  // console.log("Values Array:", valuesArray);

  let order_labels: string[];

  // Assign order labels based on type
  if (type === "week" || type === "lastweek") {
    const startOfWeek = type === "lastweek"
    ? moment().subtract(1, "weeks").startOf("week")  // Last week's Sunday
    : moment().startOf("week");  // This week's Sunday

order_labels = Array.from({ length: 7 }, (_, i) =>
    startOfWeek.clone().add(i, "days").format("DD") // Format: "Sun 09"
// startOfWeek.clone().add(i, "days").format("ddd DD") // Format: "Sun 09"

);

  } else if (type === "yesterday") {
      order_labels = ["Yesterday"];

  } else if (type === "thismonth" || type === "lastmonth" || type =="customdate") {

    const old_labels = datesArray.map(date => parseInt(date.split("-")[0], 10));
      order_labels = old_labels;

  } else if (type === "thisyear" || type === "lastyear") {
      order_labels = [
          "January", "February", "March", "April", "May", "June",
          "July", "August", "September", "October", "November", "December"
      ];

  }  else if (type === "custommonth") {
    console.log(datesArray)
    order_labels = datesArray.map(date => moment(date, "DD-MM-YYYY").format("MMMM"));

} else {
      order_labels = [`${get_data.startDate} - ${get_data.endDate}`];
  }

  console.log("Final Order Labels:", order_labels);

  // Ensure values and labels match in length
  if (order_labels.length !== firstValuesArray.length) {
      console.error("Mismatch: Labels and values count do not match!");
      SetOverviewIsLoading(false);
      return;
  }

  // Update bar chart data
  // setBarChartData((prevState: any) => ({
  //   ...prevState,
  //   series: [
  //     {
  //       name: '1st',
  //       data: firstValuesArray
  //     },
  //     {
  //       name: '2nd',
  //       data: secondValuesArray
  //     },
  //     {
  //       name: '3rd',
  //       data: thirdValuesArray
  //     },
  //     {
  //       name: '4th',
  //       data: fourthValuesArray
  //     }
  //   ],
  //   options: {
  //     ...prevState.options,
  //     xaxis: { 
  //       ...prevState.options.xaxis, 
  //       categories: order_labels // Ensure this contains formatted date labels
  //     },
  //     dataLabels: {
  //       enabled: true,
  //       formatter: (val: number) => val.toString(),
  //       offsetY: -20,
  //       style: { fontSize: "12px", fontWeight: "bold", colors: ["#304758"] }
  //     },
  //     plotOptions: { 
  //       bar: { 
  //         horizontal: false, 
  //         borderRadius: 10,
  //         borderRadiusApplication: 'end',
  //         borderRadiusWhenStacked: 'last',
  //         dataLabels:  { position: "center" }
  //       } 
  //     },
  //     chart: { 
  //       type: 'bar',
  //       height: 350,
  //       stacked: true,
  //       toolbar: { show: false },
  //       zoom: { enabled: true }
  //     },
  //     colors: ['rgb(0, 143, 251)', 'rgb(0, 227, 150)', 'rgb(254, 176, 25)', 'rgb(255, 69, 96)'], // Custom RGB colors
  //     responsive: [
  //       {
  //         breakpoint: 480,
  //         options: {
  //           legend: {
  //             position: 'bottom',
  //             offsetX: -10,
  //             offsetY: 0
  //           }
  //         }
  //       }
  //     ],
  //     legend: {
  //       position: 'right',
  //       offsetY: 40
  //     },
  //     fill: {
  //       opacity: 1
  //     }
  //   }
  // }));

  setBarChartData((prevState: any) => ({
    ...prevState,
    series: [
      {
        name: '1st',
        data: firstValuesArray
      },
      {
        name: '2nd',
        data: secondValuesArray
      },
      {
        name: '3rd',
        data: thirdValuesArray
      },
      {
        name: '4th',
        data: fourthValuesArray
      },
   {
        name: '5th+',
        data: fifthValuesArray
      },
      
    ],
    options: {
      ...prevState.options,
      xaxis: { 
        ...prevState.options.xaxis, 
        categories: order_labels ,// Ensure this contains formatted date labels
        labels: {
          style: {
            colors: "#222", // Darker text color
            fontSize: "14px", // Larger font for better visibility
            // fontWeight: "bold", // Make text bold
            fontFamily: "Arial, sans-serif" // More readable font
          }
        }
      },
      yaxis: { 
        labels: {
          style: {
            // colors: "#222", // Darker text color
            fontSize: "10px", // Larger font for better visibility
            // fontWeight: "bold", // Make text bold
            fontFamily: "Arial, sans-serif" // More readable font
          }
        }
      },
      plotOptions: { 
        bar: { 
          horizontal: false, 
          borderRadius: 10,
          borderRadiusApplication: 'end',
          borderRadiusWhenStacked: 'last',
          columnWidth: "50%", // Adjust width percentage (default is 70%)
          barHeight: "50%",
          dataLabels: { position: "center" } // Center numbers inside bars
        } 
      },
      dataLabels: {
        enabled: true,
        formatter: (val: number) => val.toString(),
        offsetY: 0, // Centers the label inside the bar
        style: { 
          fontSize: "12px", 
          fontWeight: "bold", 
          colors: ["#fff"] // White text for contrast
        },
        total: {
          enabled: true, // Show total on top of bars
          formatter: function (val) {
            return val; // Show total value
          },
          style: {
            fontSize: '14px',
            fontWeight: 'bold',
            colors: ['#000'] // Black for visibility
          }
        }
      },
      chart: { 
        type: 'bar',
        height: 350,
        stacked: true,
        toolbar: { show: false },
        zoom: { enabled: true }
      },
      colors: ['rgb(0, 143, 251)', 'rgb(0, 227, 150)', 'rgb(254, 176, 25)', 'rgb(255, 69, 96)','rgb(138, 43, 226)'], // Custom RGB colors
      responsive: [
        {
          breakpoint: 480,
          options: {
            legend: {
              position: 'bottom',
              offsetX: -10,
              offsetY: 0
            }
          }
        }
      ],
      legend: {
        position: 'right',
        offsetY: 40
      },
      fill: {
        opacity: 1
      }
    }
  }));

  SetOverviewIsLoading(false);
}

  
  return (
    <div>
    <div
    className={OutletContext ? "head-bar sticky-sm py-3" : "head-bar py-3"}
    style={OutletContext == false ? { width: width } : {}}
  >
      <div className="d-flex-lg">
        <h6 className="fs-5  mb-2">Total First Order Users Statistics</h6>
    

        <div className="ms-auto">
                    <div className="d-flex align-items-center border br-1 pr-2 bg-white">
                        <div className="dropdown">
                            <button className="btn bg-white text-muted btn-sm br-4 px-2 d-flex dropdown-toggle" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                                {/* <ImageLazyLoader className="img-fluid" width={10} src={AppConfig.CDN_Media_url+"420485dfc5e7bf2426a897a723ad243c.svg"} /> */}
                                <span className="ps-1">{statisticsViewFormatted}</span>
                            </button>
                            <ul className="dropdown-menu  dropdown-menu-end" aria-labelledby="dropdownMenuButton1">
                            <li><button type="button" className="dropdown-item" onClick={() => { SetStatisticsView('week'); }}>This Week</button></li>

                                <li><button type="button" className="dropdown-item" onClick={() => { SetStatisticsView('lastweek'); }}>Last Week</button></li>

                                {/* <li><button type="button" className="dropdown-item" onClick={() => { SetStatisticsView('pastweek'); }}>Past Week</button></li> */}

                                {/* <li><button type="button" className="dropdown-item" onClick={() => { SetStatisticsView('pastmonth'); }}>Past Month</button></li> */}
                                <li><button type="button" className="dropdown-item" onClick={() => { SetStatisticsView('thismonth'); }}>This Month</button></li>

                                <li><button type="button" className="dropdown-item" onClick={() => { SetStatisticsView('lastmonth'); }}>Last Month</button></li>
                                
                                <li><button type="button" className="dropdown-item" onClick={() => { SetStatisticsView('thisyear'); }}>This Year</button></li>


                                <li><button type="button" className="dropdown-item" onClick={() => { SetStatisticsView('lastyear'); }}>Last Year</button></li>
     
                                <li><button type="button" className="dropdown-item"
                                 onClick={() => {   
                                  SetStatisticsView('customdate');
                                  SetStatisticsView('customdate');
                                  
                                  let set_data = {
                                    start:startDate,
                                    end:endDate,
                                    from:"customdate"
                                       
                                        
                                      
                                        
                                      };
                                      setTimeModal({
                                        is_open: true,
                                        data: set_data,
                                      });}}>Custom Date Wise</button></li>
                                       <li><button type="button" className="dropdown-item"
                                 onClick={() => {   
                                  SetStatisticsView('custommonth');
                                  
                                  let set_data = {
                                    start:startDate,
                                    end:endDate,
                                    from:"custommonth"

                                       
                                      
                                        
                                      };
                                      setTimeModal({
                                        is_open: true,
                                        data: set_data,
                                      });}}>Custom Month Wise</button></li>
                                
                            </ul>
                        </div>
                        <div className="text-secondary  me-2 fs-12 cursor pr-1" 
                              onClick={() => {

                                let set_data = {
                                start:startDate,
                                end:endDate,
                                from:"brand"
                                   
                                    
                                  
                                    
                                  };
                                  setTimeModal({
                                    is_open: true,
                                    data: set_data,
                                  });
                            }}
                        // data-toggle="modal" data-target="#customDate"
                        >{startDateShow}  - {endDateShow}</div>
                    </div>
                    </div>
        </div>
        </div>   <div className="d-flex align-items-center">
 

    

        </div>

        <div className="card m-1 mt-sm-6 mobile-bar shadow" 
        style={{ overflowX: 'auto', maxWidth: '100%' }}> 
<div style={{ minWidth: (statisticsView === "lastweek" || statisticsView === "week") ? "" : "500px" }}>
{/* <div > */}

        {/* <div style={{ minWidth: ["thismonth", "lastmonth", "customdate"].includes(statisticsView) ? "800px" : "100%" }}> */}

<ReactApexChart options={barChartData.options} series={barChartData.series} type="bar" height={400} />
</div>
</div>

<ModalTime
        open={time_modal.is_open}
        data={time_modal.data}
     
        close={(data: any) => {
          console.log("Edit Outlet Modal On Close :", data);
          setTimeModal({
            is_open: false,
            data: {},
          
          });
          if (data.action == "save") {
            // SetStatistics(data.data?.data);
            console.log(data.data?.from)

            if( data.data?.from == "custommonth"){

           let monthwise_custom = getMonthWiseData(data.data?.start, data.data?.end);
           get_overview(monthwise_custom,"custommonth");
SetStatisticsView("custommonth")
console.log("custommonth",data)




            }else if( data.data?.from == "customdate"){
              console.log("customdate",data)
              let daywise_custom = getCustomDateRange(data.data?.start, data.data?.end);
              get_overview(daywise_custom,"customdate");
   SetStatisticsView("customdate")
              
            }

           
            setStartDate(data.data?.start)
            setEndDate(data.data?.end)
            setStartDateShow(data.data.start)
            setEndDateShow(data.data.end)

            const convertedDateTime = convertDateTime(data.data?.start_display);

            // SetStatisticsViewFormatted("custom")

            // let send_data = {
            //   startDate: data.data?.start,
            //   endDate: data.data?.end,
            // };
            // get_overview(send_data);
           

      
            // const convertedEndDateTime = convertDateTime(data.data?.end_display);
            // setStartDateShow(convertedDateTime)
            // setEndDateShow(convertedEndDateTime)

            
          
     
          }
        }}
      />
</div>
  )
}
export default OutletTotalOrderStatistics