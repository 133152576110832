import React, { useState, useEffect, useContext } from "react";
import { Link, useParams,useNavigate, useLocation  } from "react-router-dom";
import DataContext from "../../utils/Context/DataContext";
import {
  Ionicons,
  Entypo,
  Fontisto,
  AntDesign,
  FontAwesome,
  MaterialCommunityIcons,
  Feather,
  MaterialIcons,
  FontAwesome5,
} from "@expo/vector-icons";
import {
  Dialog,
  Drawer,
  Modal,
  SwipeableDrawer,
  Slide,
} from "@material-ui/core";
import SwipeableBottomSheet from "@geomatico/react-swipeable-bottom-sheet";
// import { Rating } from "@material-ui/lab";
import { api } from "../../utils/Api";
import toast from "react-hot-toast";
import bootstrap from "../../assets/libraries/bootstrap/js/bootstrap";
import { AppConfig } from "../../config";
let map_api = AppConfig.api;
((g: any) => {
  var h: any,
    a: any,
    k: any,
    p = "The Google Maps JavaScript API",
    c: any = "google",
    l = "importLibrary",
    q = "__ib__",
    m: any = document,
    b: any = window;
  b = b[c] || (b[c] = {});
  var d = b.maps || (b.maps = {}),
    r: any = new Set(),
    e = new URLSearchParams(),
    u = () =>
      h ||
      (h = new Promise(async (f: any, n: any) => {
        await (a = m.createElement("script"));
        e.set("libraries", [...r] + "");
        for (k in g)
          e.set(
            k.replace(/[A-Z]/g, (t: any) => "_" + t[0].toLowerCase()),
            g[k]
          );
        e.set("callback", c + ".maps." + q);
        a.src = `https://maps.${c}apis.com/maps/api/js?` + e;
        d[q] = f;
        a.onerror = () => (h = n(Error(p + " could not load.")));
        a.nonce = m.querySelector("script[nonce]")?.nonce || "";
        m.head.append(a);
      }));
  d[l]
    ? console.warn(p + " only loads once. Ignoring:", g)
    : (d[l] = (f: any, ...n: any) => r.add(f) && u().then(() => d[l](f, ...n)));
})({ key: map_api, v: "weekly" });

var driver_marker: any;
let counter = 0;
const map_styler = [
  {
    featureType: "administrative",
    elementType: "geometry",
    stylers: [
      {
        visibility: "off",
      },
    ],
  },
  {
    featureType: "poi",
    stylers: [
      {
        visibility: "off",
      },
    ],
  },
  {
    featureType: "road",
    elementType: "labels.icon",
    stylers: [
      {
        visibility: "off",
      },
    ],
  },
  {
    featureType: "transit",
    stylers: [
      {
        visibility: "off",
      },
    ],
  },
];
var importedScript = document.createElement("script");
let map: any;
let myModal: any;
let live_tracking_call_interval: any;
let live_tracking_call_interval1: any;
let driver_move_call_interval: any;
let driver_move_call_interval1: any;
let order_details_value = {
  lat: "",
  lng: "",
  delivery_address: {
    lat: "",
    lng: "",
  },
};

let marker_img_url = "https://cdn3.1superapp.com/superapp/deliveryboy.png";
let initialTime: any = 0;
// loadScript(
//   "https://maps.googleapis.com/maps/api/js?key=AIzaSyCpb5cwbblyXgVG-ZsUdA4WdUSFWhBxgWE&callback=initMap&v=weekly&libraries=places"
// );

// async function initMap(): Promise<void> {
//   console.log("initMap :",order_details_value);
//   // const directionsService = new google.maps.DirectionsService();
//   // const directionsRenderer = new google.maps.DirectionsRenderer();
//   const { Map }: any = await google.maps.importLibrary("maps");

//   // map = new google.maps.Map(
//   //   document.getElementById("map-live-track") as HTMLElement,
//   //   {
//   map = new Map(document.getElementById("map-live-track") as HTMLElement, {
//     zoom: 12,
//     // center: {
//     //   lat: parseFloat(props.order_details.outlet_lat),
//     //   lng: parseFloat(props.order_details.outlet_lng),
//     // },
//     center: {
//       lat: parseFloat(order_details_value.lat),
//       lng: parseFloat(order_details_value.lng),
//     },
//     mapTypeControl: false,
//     fullscreenControl: false,
//     zoomControl: false,
//     streetViewControl: false,
//     mapTypeId: "roadmap",
//     gestureHandling: "greedy",
//     styles: map_styler,
//     mapId: "DEMO_MAP_ID",

//   });

//   // directionsRenderer.setMap(map);

//   driver_marker = new google.maps.Marker({
//     position: null,
//     map,
//     icon: {
//       url: marker_img_url,
//       scaledSize: new google.maps.Size(23, 35),
//     },
//     anchorPoint: new google.maps.Point(0, -29),
//   });

//   // resturant marker
//   new google.maps.Marker({
//     // position: {
//     //   lat: parseFloat(props.order_details.outlet_lat),
//     //   lng: parseFloat(props.order_details.outlet_lng),
//     // },
//     position: {
//       lat: parseFloat(order_details_value.lat),
//       lng: parseFloat(order_details_value.lng),
//     },
//     map: map,
//     //   icon: "https://img.icons8.com/external-flaticons-flat-flat-icons/33/external-customer-internet-marketing-service-flaticons-flat-flat-icons-3.png",
//     // icon: "https://cdn-icons-png.flaticon.com/32/2499/2499159.png",
//     icon: {
//       url: "https://cdn3.1superapp.com/superapp/markerpinup.png",
//       scaledSize: new google.maps.Size(23, 35),
//     },
//     anchorPoint: new google.maps.Point(0, -29),
//   });
//   // customer marker
//   new google.maps.Marker({
//     // position: {
//     //   lat: parseFloat(props.order_details.delivery_address.lat),
//     //   lng: parseFloat(props.order_details.delivery_address.lon),
//     // },
//     position: {
//       lat: parseFloat(order_details_value.delivery_address.lat),
//       lng: parseFloat(order_details_value.delivery_address.lng),
//     },
//     map: map,
//     //   icon: "https://img.icons8.com/external-flaticons-flat-flat-icons/33/external-customer-internet-marketing-service-flaticons-flat-flat-icons-3.png",
//     // icon: "https://cdn-icons-png.flaticon.com/32/70/70770.png",
//     icon: {
//       url: "https://cdn3.1superapp.com/superapp/markerpindown.png",
//       scaledSize: new google.maps.Size(23, 35),
//     },
//     anchorPoint: new google.maps.Point(0, -29),
//   });
// }

function loadScript(url: any) {
  var index: any = window.document.getElementsByTagName("script")[0];
  var script = window.document.createElement("script");
  script.id = "google_map";
  script.src = url;
  // script.async = true;
  script.defer = true;
  index.parentNode.insertBefore(script, index);
}

export default function ModalOrderTracking(props: any) {
  const [id_value, SetIdValue] = useState(useParams());
  const context: any = useContext(DataContext);
  const [oldlatlng, SetOldLatLng] = useState([]);
  const [show_prepare_food, SetShowPrepareFood] = useState(true);
  const [show_looking_for_delivery_person, SetShowLookingForDeliveryPerson] =
    useState(true);
  const [show_delivery_person, SetShowDeliveryPerson] = useState(false);
  const [called_live_tracking, SetCalledLiveTracking] = useState(false);
  const [outlet_lat_lng, SetOutletLatLng] = useState({});
  const [page_no, SetPageNo] = useState("");
  const [current_location, SetCurrentLocation] = useState([]);
  const [new_route_called, SetNewRouteCalled] = useState(false);
  const [width, SetWidth] = React.useState(innerWidth);
  const [timer_called, setTimerCalled] = useState(false);
  const navigate = useNavigate();
  const location = useLocation(); 
  useEffect(() => {
    console.log("ModalOrderTracking On mount :", props);
    let myModal1: any = document.getElementById("MapTracker");

    myModal1.addEventListener("hidden.bs.modal", function (event: any) {
      console.log("event :", event);
      clearInterval(live_tracking_call_interval);
      clearInterval(live_tracking_call_interval1);
      clearInterval(driver_move_call_interval);
      clearInterval(driver_move_call_interval1);
      setTimerCalled(false);
      order_details_value = {
        lat: "",
        lng: "",
        delivery_address: {
          lat: "",
          lng: "",
        },
      };

      importedScript.remove();
      SetNewRouteCalled(false);
      SetCalledLiveTracking(false);
      props.close();
    });

    return () => {
      clearInterval(live_tracking_call_interval);
      clearInterval(live_tracking_call_interval1);
      clearInterval(driver_move_call_interval);
      clearInterval(driver_move_call_interval1);
    };
  }, []);

  useEffect(() => {
    if (props.open) {
      console.log("props on change ModalOrderTracking component :", props);
      myModal = new bootstrap.Modal(document.getElementById("MapTracker"), {
        backdrop: false,
      });
      //   SetOutletLatLng({
      //     lat: parseFloat(props.order_details.outlet_lat),
      //     lng: parseFloat(props.order_details.outlet_lng),
      //   });
      order_details();
      // // renderMap();
      // process_props_data();
      initMap();
      myModal.show();
    }

        // Listen for back button press
        const onPopState = () => {
          // Close the modal when back button is pressed
          if (myModal) {
            myModal.hide();
            props.close();
          }
          navigate(location.pathname);
        };
    
        // window.addEventListener('popstate', onPopState);
    
        return () => {
          // window.removeEventListener('popstate', onPopState);
          if (myModal) {
            myModal.hide();
          }
        };
  }, [props.open]);
  const renderPorterDeliveries = (porterDeliveries) => {
    if (!porterDeliveries?.length) return null;

    const lastDelivery = porterDeliveries[porterDeliveries.length - 1];
    const lastRider = lastDelivery?.riders?.[lastDelivery.riders.length - 1];

    return lastRider ? (
      <div className="border-top pt-2" key={lastDelivery.id}>
        <div className="d-flex">
          <div className="ms-2">
            {/* <p className="extra-small text-gray pb-1">Porter Delivery</p> */}
            <p>
              <span className="fw-bold text-black">{lastRider.driver_name}</span>
              <span className="px-1"></span>
              <span className="text-green v-small ps-0">{lastDelivery.status}</span>
            </p>
          </div>
          <div className="d-flex align-items-center ms-auto">
            {/* Open tracking modal for Porter */}
      
             
            {/* Direct call button for last rider */}
            {lastRider.driver_mobile_number && (
              <div
                style={{ cursor: "pointer"}}
                onClick={(event) => {
                  event.stopPropagation();
                  window.location.href = `tel:${lastRider.driver_mobile_country_code}${lastRider.driver_mobile_number}`;
                }}
              >
                <img src={require("../../assets/icon/phone.png")} className="bank-img me-4" alt="Call" />

                           
              </div>
            )}
          </div>
        </div>
      </div>
    ) : null;
  };
  const renderModalContent = () => (
    <div className="modal-content">
      <div className="modal-header map-header">
        <div className="ps-1 pe-0 w-100">
          <p
            className="fs-7 text-dark mb-0 fw-500"
            onClick={() => {
              console.log("props :", props);
            }}
          >
            Order ID: #{props.order_details?.id}
          </p>
          <div className="d-flex align-items-center">
            <p className="text-gray pe-1">
              {props.order_details?.products?.length} Item | ₹
             { props.order_details.hasOwnProperty("pickup_drop") ?  props.order_details?.total_amount :  props.order_details?.amount}
         
            </p>
          </div>
        </div>
        <button
          type="button"
          className="btn-close"
          data-bs-dismiss="modal"
          aria-label="Close"
        ></button>
      </div>
      <div className="map-modal-body p-0">
        <div className="row p-0">
          <div className="col-md-7 col-12 p-0">
            <div id="map-live-track"></div>
          </div>
          {width > 720 ? (
            <div className="col-md-5 pt-3 bg-fff">
              <StatusPanel />
            </div>
          ) : (
            <div className="map-over center bg-fff">
              <SwipeableBottomSheet overflowHeight={150}>
                <div
                  className="p-2 bg-fff sticky-top custom-swipe-shadow"
                  style={{ height: "600px" }}
                >
                  <div className="center pb-3">
                    <hr className="hr-drawer" />
                  </div>
                  <div className="mb-4">
                  <StatusPanel />
                  </div>
                </div>
              </SwipeableBottomSheet>
            </div>
          )}
        </div>
      </div>
    </div>
  );

  const renderMap = () => {
    // loadScript(
    //   "https://maps.googleapis.com/maps/api/js?key=AIzaSyCpb5cwbblyXgVG-ZsUdA4WdUSFWhBxgWE&callback=initMap&v=weekly&libraries=geometry"
    // );
    if (!document.getElementById("google_map")) {
      // loadScript(
      //   "https://maps.googleapis.com/maps/api/js?key=AIzaSyCpb5cwbblyXgVG-ZsUdA4WdUSFWhBxgWE&callback=initMap&v=weekly&libraries=places"
      // );
      // importedScript.id = "google_map";
      // importedScript.src =
      //   "https://maps.googleapis.com/maps/api/js?key=AIzaSyCpb5cwbblyXgVG-ZsUdA4WdUSFWhBxgWE&callback=initMap&v=weekly&libraries=places";
      // document.head.appendChild(importedScript);
      // console.log("importedScript :", importedScript);
    }

    window.initMap = initMap;
  };

  const order_details = () => {
    if (props.order_details.hasOwnProperty("seller") &&
    Object.keys(props.order_details.seller).length > 0) {
      console.log("seller")
      order_details_value = {
        lat: props?.order_details?.seller?.outlet?.lat,
        lng: props?.order_details?.seller?.outlet?.lng,
        delivery_address: {
          lat: props?.order_details?.delivery_details?.delivery_lat,
          lng: props?.order_details?.delivery_details?.delivery_lng,
        },
      };
    }
    else
     if(props.order_details.hasOwnProperty("pickup_drop")){
      console.log("pickup")
      order_details_value = {
        lat: props?.order_details?.customer?.pickup_address?.lat,
        lng: props?.order_details?.customer?.pickup_address?.lng,
        delivery_address: {
          lat: props?.order_details?.customer?.delivery_address?.lat,
          lng: props?.order_details?.customer?.delivery_address?.lng,
        },
      };
    }
        renderMap();
        process_props_data();
        // initMap();
  };

function initMap(): void {
    console.log("initMap :",order_details_value);
    // const directionsService = new google.maps.DirectionsService();
    // const directionsRenderer = new google.maps.DirectionsRenderer();
    // const { Map }: any =  google.maps.importLibrary("maps");
  
    // map = new google.maps.Map(
    //   document.getElementById("map-live-track") as HTMLElement,
    //   {
    map = new google.maps.Map(document.getElementById("map-live-track") as HTMLElement, {
      zoom: 12,
      // center: {
      //   lat: parseFloat(props.order_details.outlet_lat),
      //   lng: parseFloat(props.order_details.outlet_lng),
      // },
      center: {
        lat: parseFloat(order_details_value.lat),
        lng: parseFloat(order_details_value.lng),
      },
      mapTypeControl: false,
      fullscreenControl: false,
      zoomControl: false,
      streetViewControl: false,
      mapTypeId: "roadmap",
      gestureHandling: "greedy",
      styles: map_styler,
      mapId: "DEMO_MAP_ID",
  
    });
  
    // directionsRenderer.setMap(map);
  
    driver_marker = new google.maps.Marker({
      position: null,
      map,
      icon: {
        url: marker_img_url,
        scaledSize: new google.maps.Size(23, 35),
      },
      anchorPoint: new google.maps.Point(0, -29),
    });
  
    // resturant marker
    new google.maps.Marker({
      // position: {
      //   lat: parseFloat(props.order_details.outlet_lat),
      //   lng: parseFloat(props.order_details.outlet_lng),
      // },
      position: {
        lat: parseFloat(order_details_value.lat),
        lng: parseFloat(order_details_value.lng),
      },
      map: map,
      //   icon: "https://img.icons8.com/external-flaticons-flat-flat-icons/33/external-customer-internet-marketing-service-flaticons-flat-flat-icons-3.png",
      // icon: "https://cdn-icons-png.flaticon.com/32/2499/2499159.png",
      icon: {
        url: "https://cdn3.1superapp.com/superapp/markerpinup.png",
        scaledSize: new google.maps.Size(23, 35),
      },
      anchorPoint: new google.maps.Point(0, -29),
    });
    // customer marker
    new google.maps.Marker({
      // position: {
      //   lat: parseFloat(props.order_details.delivery_address.lat),
      //   lng: parseFloat(props.order_details.delivery_address.lon),
      // },
      position: {
        lat: parseFloat(order_details_value.delivery_address.lat),
        lng: parseFloat(order_details_value.delivery_address.lng),
      },
      map: map,
      //   icon: "https://img.icons8.com/external-flaticons-flat-flat-icons/33/external-customer-internet-marketing-service-flaticons-flat-flat-icons-3.png",
      // icon: "https://cdn-icons-png.flaticon.com/32/70/70770.png",
      icon: {
        url: "https://cdn3.1superapp.com/superapp/markerpindown.png",
        scaledSize: new google.maps.Size(23, 35),
      },
      anchorPoint: new google.maps.Point(0, -29),
    });
  }

  const Spinner = () => <div className="loader-spinner"></div>;

  useEffect(() => {
    console.log("order Details on change props : ", props);
    // process_props_data();
  }, [props.order_details]);

  function process_props_data() {
    console.log(
      "process_props_data :",
      props.order_details.hasOwnProperty("shipments")
    );
    // props.order_details.hasOwnProperty("shipments") &&
    // props.order_details.shipments[props.delivery_index]?.hasOwnProperty(
    //   "hyperlocal_pickup"
    // ) &&
    // props.open
    // if (props.order_details.sector_id == 4) {
    //   setTimerCalled((prevalue: any) => {
    //     if (!prevalue) {
    //       if (props.order_details.status_id != 6 && props.order_details.status_id != 4) {
    //         // clearTimer(getDeadTime(props.order_details.etd_duration_seconds));
    //         // setTimeLeft(props.order_details.etd_duration_seconds);
    //       }
    //       initialTime = props.order_details.pickup_drop[props.shipment_index]?.delivery_partner[props.delivery_index].etd_delivery_duration;
    //       prevalue = true;
    //     }
    //     return prevalue;
    //   });

    //   // pickupdrop
    //   if (
    //     props.order_details?.hasOwnProperty("pickup_drop") &&
    //     Object.keys(props.order_details.pickup_drop).length > 0
    //   ) {
    //     if (props.order_details?.pickup_drop[props.shipment_index]?.status_id > 5) {
    //       // map line connect to drop location
    //       // clearInterval(live_tracking_call_interval);
    //       // clearInterval(driver_move_call_interval);
    //       SetNewRouteCalled((prevalue: any) => {
    //         console.log("SetNewRouteCalled prevalue :", prevalue);
    //         if (prevalue == false && prevalue != undefined) {
    //           let send_data = {
    //             page: "",
    //             first_time: true,
    //             type: "2",
    //             sector_id: props.order_details.sector_id,
    //             order_data: props.order_details,
    //           };
    //           get_live_tracking(send_data);
    //           live_tracking_call_interval = setInterval(function () {
    //             console.log(
    //               "setInterval on call live_tracking_call_interval get_live_tracking:"
    //             );
    //             SetPageNo((prevalue1: any) => {
    //               let send_data = {
    //                 page: prevalue1,
    //                 first_time: false,
    //                 type: "2",
    //                 sector_id: props.order_details.sector_id,
    //                 order_data: props.order_details,
    //               };
    //               console.log("SetPageNo prevalue1 :", prevalue1);
    //               console.log("send_data :", send_data);
    //               get_live_tracking(send_data);
    //               return prevalue1;
    //             });
    //           }, 10000);
    //           driver_move_call_interval = setInterval(function () {
    //             driver_move(
    //               props.order_details.customer.delivery_address.lat,
    //               props.order_details.customer.delivery_address.lng,
    //               false,
    //               1
    //             );
    //             console.log("driver move call 1");
    //           }, 1000);
    //           prevalue = true;
    //         }
    //         return prevalue;
    //       });
    //     } else {
    //       // map line connect to pickup location
    //       console.log("not staus id 5")
    //       SetCalledLiveTracking((prevalue: any) => {
    //         if (!prevalue) {
    //           let send_data = {
    //             page: "",
    //             first_time: true,
    //             type: "1",
    //             sector_id: props.order_details.sector_id,
    //             order_data: props.order_details,
    //           };
    //           get_live_tracking(send_data);
    //           live_tracking_call_interval = setInterval(function () {
    //             SetPageNo((prevalue1: any) => {
    //               let send_data = {
    //                 page: prevalue1,
    //                 first_time: false,
    //                 type: "1",
    //                 sector_id: props.order_details.sector_id,
    //                 order_data: props.order_details,
    //               };
    //               get_live_tracking(send_data);
    //               return prevalue1;
    //             });
    //           }, 10000);
    //           driver_move_call_interval = setInterval(function () {
    //             driver_move(
    //               props.order_details.customer.delivery_address.lat,
    //               props.order_details.customer.delivery_address.lng,
    //               false,
    //               2
    //             );
    //             console.log("driver move call 2");
    //           }, 1000);
    //           prevalue = true;
    //         }
    //         return prevalue;
    //       });
    //     }
    //     //
    //   }
    // } else
    if (
      props.order_details.hasOwnProperty("shipments") &&
      props.order_details.shipments[props.shipment_index]?.hasOwnProperty(
        "super_delivery_order"
      ) &&
      props.open
    ) {
      if (
        Object.keys(
          props.order_details.shipments[props.shipment_index]
            ?.super_delivery_order
        ).length > 0
      ) {
        // SetShowPrepareFood(false);
        SetShowLookingForDeliveryPerson(false);
        SetShowDeliveryPerson(true);

        if (
          props.order_details.shipments[props.shipment_index]
            ?.super_delivery_order[0].delivery_partner[props.delivery_index]
            .status_id > 5
        ) {
          clearInterval(live_tracking_call_interval);
          clearInterval(live_tracking_call_interval1);
          clearInterval(driver_move_call_interval);
          clearInterval(driver_move_call_interval1);
          SetNewRouteCalled((prevalue: any) => {
            if (prevalue == false && prevalue != undefined) {
              let send_data = {
                page: "",
                first_time: true,
                type: "2",
                sector_id: props.order_details.sector_id,
                sample_value: 1,
              };
              get_live_tracking(send_data);
              live_tracking_call_interval = setInterval(function () {
                SetPageNo((prevalue1: any) => {
                  let send_data = {
                    page: prevalue1,
                    first_time: false,
                    type: "2",
                    sector_id: props.order_details.sector_id,
                    sample_value: 2,
                  };
                  get_live_tracking(send_data);
                  return prevalue1;
                });
              }, 10000);
              driver_move_call_interval = setInterval(function () {
                driver_move(
                  props.order_details.customer.delivery_address.lat,
                  props.order_details.customer.delivery_address.lng
                );
              }, 1000);
            }
            return prevalue;
          });
        } else {
          SetCalledLiveTracking((prevalue: any) => {
            if (!prevalue) {
              let send_data = {
                page: "",
                first_time: true,
                type: "1",
                sector_id: props.order_details.sector_id,
                sample_value: 3,
              };
              get_live_tracking(send_data);
              live_tracking_call_interval1 = setInterval(function () {
                SetPageNo((prevalue1: any) => {
                  let send_data = {
                    page: prevalue1,
                    first_time: false,
                    type: "1",
                    sector_id: props.order_details.sector_id,
                    sample_value: 4,
                  };
                  get_live_tracking(send_data);
                  return prevalue1;
                });
              }, 10000);
              console.log("props.order_details", props.order_details);
              driver_move_call_interval1 = setInterval(function () {
                driver_move(
                  props.order_details.customer.delivery_address.lat,
                  props.order_details.customer.delivery_address.lng
                );
              }, 1000);
            }
            return prevalue;
          });
        }
      }

      if (
        props.order_details.shipments[props.delivery_index]?.status_id == 3 ||
        props.order_details.shipments[props.delivery_index]?.status_id == 4
      ) {
        // accepted
        console.log("hi");
        SetShowPrepareFood(true);
      } else {
        SetShowPrepareFood(false);
      }
    }

    if (
      props.order_details.hasOwnProperty("pickup_drop") &&
      props.open
    ) {
      if (
        Object.keys(
          props.order_details.pickup_drop
        )
      ) {
        // SetShowPrepareFood(false);
        SetShowLookingForDeliveryPerson(false);
        SetShowDeliveryPerson(true);

        if (
          props?.order_details.pickup_drop[props.shipment_index]?.delivery_partner[props.delivery_index]?.status_id > 5
        ) {
          clearInterval(live_tracking_call_interval);
          clearInterval(live_tracking_call_interval1);
          clearInterval(driver_move_call_interval);
          clearInterval(driver_move_call_interval1);
          SetNewRouteCalled((prevalue: any) => {
            if (prevalue == false && prevalue != undefined) {
              let send_data = {
                page: "",
                first_time: true,
                type: "2",
                sector_id: props.order_details.sector_id,
                sample_value: 1,
              };
              get_live_tracking(send_data);
              live_tracking_call_interval = setInterval(function () {
                SetPageNo((prevalue1: any) => {
                  let send_data = {
                    page: prevalue1,
                    first_time: false,
                    type: "2",
                    sector_id: props.order_details.sector_id,
                    sample_value: 2,
                  };
                  get_live_tracking(send_data);
                  return prevalue1;
                });
              }, 10000);
              driver_move_call_interval = setInterval(function () {
                driver_move(
                  props.order_details.customer.delivery_address.lat,
                  props.order_details.customer.delivery_address.lng
                );
              }, 1000);
            }
            return prevalue;
          });
        } else {
          SetCalledLiveTracking((prevalue: any) => {
            if (!prevalue) {
              let send_data = {
                page: "",
                first_time: true,
                type: "1",
                sector_id: props.order_details.sector_id,
                sample_value: 3,
              };
              get_live_tracking(send_data);
              live_tracking_call_interval1 = setInterval(function () {
                SetPageNo((prevalue1: any) => {
                  let send_data = {
                    page: prevalue1,
                    first_time: false,
                    type: "1",
                    sector_id: props.order_details.sector_id,
                    sample_value: 4,
                  };
                  get_live_tracking(send_data);
                  return prevalue1;
                });
              }, 10000);
              console.log("props.order_details", props.order_details);
              driver_move_call_interval1 = setInterval(function () {
                driver_move(
                  props.order_details.customer.delivery_address.lat,
                  props.order_details.customer.delivery_address.lng
                );
              }, 1000);
            }
            return prevalue;
          });
        }
      }

      if (
        props.order_details.pickup_drop[props.shipment_index]?.status_id == 3 ||
        props.order_details.pickup_drop[props.shipment_index]?.status_id == 4
      ) {
        // accepted
        console.log("hi");
        SetShowPrepareFood(true);
      } else {
        SetShowPrepareFood(false);
      }
    }
  }

  async function get_live_tracking(get_data: any) {
    console.log("get_live_tracking get_data :", get_data);
    let pass_data = {
      get: {
        // order_id: props.order_details.id,
        // delivery_id: props.delivery_id,
        // page: get_data.page,
        // brand: id_value.brand_id,
        // outlet: id_value.outlet_id,
        order_id: props.order_details.id,
    ...(props.hasOwnProperty("delivery_id") && props.delivery_id !== "" 
        ? { delivery_id: props.delivery_id} 
        : {}),
        // delivery_id: props.pickup_drop[props.shipment_index].delivery_partner[props.delivery_index].delivery_id,

        // page: get_data.page,
      },
    };

    console.log("get_live_tracking pass_data :", pass_data);
    let response = await api(
      "/orders/live_tracking",
      pass_data,
      false,
      false,
      "form_data",
      false
    );
    console.log("get_live_tracking response :", response);
    if (response.status_code == 200) {
      SetCalledLiveTracking(true);
      SetNewRouteCalled(true);

      if (response.response.hasOwnProperty("location")) {
        let page_no_value =
          response.response.location[response.response.location.length - 1]
            .pageno;
        console.log("get_live_tracking response page no  :", page_no_value);
        console.log(
          "get_live_tracking response page no  :",
          response.response.location[0]
        );

        SetPageNo(page_no_value);
        SetCurrentLocation((prevalue: any) => {
          for (var i = 0; i < response.response.location.length; i++) {
            prevalue.push(response.response.location[i]);
          }
          console.log("SetCurrentLocation prevalue :", prevalue);
          return [...prevalue];
        });
        // SetCalledLiveTracking(true);
        if (get_data.first_time) {
          if (get_data.type == 2) {
            console.log("type 2");
            let send_data: any;
            if (get_data.sector_id == 4) {
              send_data = {
                customer_location: {
                  lat: parseFloat(
                    props.order_details.customer.delivery_address.lat
                  ),
                  lng: parseFloat(
                    props.order_details.customer.delivery_address.lng
                  ),
                },
                restaurant_location: {
                  lat: parseFloat(
                    props.order_details.customer.pickup_address.lat
                  ),
                  lng: parseFloat(
                    props.order_details.customer.pickup_address.lat
                  ),
                },
                delivery_person_location: {
                  lat: parseFloat(response.response.location[0].lat),
                  lng: parseFloat(response.response.location[0].lng),
                },
              };
            } else {
              send_data = {
                restaurant_location: {
                  lat: parseFloat(props.order_details.seller.outlet.lat),
                  lng: parseFloat(props.order_details.seller.outlet.lng),
                },
                customer_location: {
                  lat: parseFloat(
                    props.order_details.customer.delivery_address.lat
                  ),
                  lng: parseFloat(
                    props.order_details.customer.delivery_address.lng
                  ),
                },
                delivery_person_location: {
                  lat: parseFloat(response.response.location[0].lat),
                  lng: parseFloat(response.response.location[0].lng),
                },
              };
            }
            console.log(
              "calling showNewDirections from type 2 get_live_tracking ",
              get_data
            );
            // showNewDirections(send_data); //hided in 31/08/2024
            // SetNewRouteCalled(true);
          }

          if (get_data.type == 1) {
            let send_data: any;
            if (get_data.sector_id == 4) {
              send_data = {
                customer_location: {
                  lat: parseFloat(
                    props.order_details.customer.delivery_address.lat
                  ),
                  lng: parseFloat(
                    props.order_details.customer.delivery_address.lng
                  ),
                },
                restaurant_location: {
                  lat: parseFloat(
                    props.order_details.customer.pickup_address.lat
                  ),
                  lng: parseFloat(
                    props.order_details.customer.pickup_address.lng
                  ),
                },
                delivery_person_location: {
                  lat: parseFloat(response.response.location[0].lat),
                  lng: parseFloat(response.response.location[0].lng),
                },
              };
            } else {
              console.log("resturant");
              send_data = {
                customer_location: {
                  // lat: parseFloat(props.order_details.delivery_address.lat),
                  // lng: parseFloat(props.order_details.delivery_address.lon),
                  lat: parseFloat(
                    props.order_details.customer.delivery_address.lat
                  ),
                  lng: parseFloat(
                    props.order_details.customer.delivery_address.lng
                  ),
                },
                restaurant_location: {
                  lat: parseFloat(props.order_details.seller.outlet.lat),
                  lng: parseFloat(props.order_details.seller.outlet.lng),
                },
                delivery_person_location: {
                  lat: parseFloat(response.response.location[0].lat),
                  lng: parseFloat(response.response.location[0].lng),
                },
              };
            }

            // showDirections(send_data); // hided in 31/08/2024
          }
          console.log("props.order_details", props.order_details);
          driver_move(
            props.order_details.customer.delivery_address.lat,
            props.order_details.customer.delivery_address.lng
          );
        }else{
          driver_move(
            props.order_details.customer.delivery_address.lat,
            props.order_details.customer.delivery_address.lng
          );
        }
      }
    }
  }

  function showDirections(get_data: any) {
    console.log(get_data);
    var directionsService = new google.maps.DirectionsService();
    var directionsRenderer = new google.maps.DirectionsRenderer({
      map: map,
      suppressMarkers: true,
    });

    // Calculate the route between the two locations
    directionsService?.route(
      {
        origin: get_data.delivery_person_location,
        destination: get_data.customer_location,
        // waypoints: [
        //   { location: get_data.restaurant_location, stopover: false },
        // ],
        travelMode: google.maps.TravelMode.DRIVING,
      },
      function (response, status) {
        if (status == "OK") {
          // Display the route on the map
          directionsRenderer.setDirections(response);
          map.setZoom(14);
        } else {
          // Handle errors
          console.error(status);
        }
      }
    );
  }

  function showNewDirections(get_data: any) {
    console.log(get_data);
    var directionsService = new google.maps.DirectionsService();
    var directionsRenderer = new google.maps.DirectionsRenderer({
      map: map,
      suppressMarkers: true,
    });

    // Calculate the route between the two locations
    directionsService?.route(
      {
        origin: get_data.delivery_person_location,
        destination: get_data.customer_location,
        travelMode: google.maps.TravelMode.DRIVING,
      },
      function (response, status) {
        if (status == "OK") {
          // Display the route on the map
          directionsRenderer.setDirections(response);
          map.setZoom(14);
        } else {
          // Handle errors
          console.error(status);
        }
      }
    );
  }

  function driver_move(lat: any, lon: any) {
    SetCurrentLocation((prevalue: any) => {
      console.log("SetCurrentLocation prevalue :", prevalue);
      const start = new google.maps.LatLng(
        parseFloat(oldlatlng[oldlatlng.length - 1]?.lat),
        parseFloat(oldlatlng[oldlatlng.length - 1]?.lng)
      );
      const end = new google.maps.LatLng(
        parseFloat(prevalue[0]?.lat),
        parseFloat(prevalue[0]?.lng)
      );

      // const heading = google.maps.geometry.spherical.computeHeading(start, end);
      const heading_api = prevalue[0]?.heading;

      // old start

      // var car =
      //   "M17.402,0H5.643C2.526,0,0,3.467,0,6.584v34.804c0,3.116,2.526,5.644,5.643,5.644h11.759c3.116,0,5.644-2.527,5.644-5.644 V6.584C23.044,3.467,20.518,0,17.402,0z M22.057,14.188v11.665l-2.729,0.351v-4.806L22.057,14.188z M20.625,10.773 c-1.016,3.9-2.219,8.51-2.219,8.51H4.638l-2.222-8.51C2.417,10.773,11.3,7.755,20.625,10.773z M3.748,21.713v4.492l-2.73-0.349 V14.502L3.748,21.713z M1.018,37.938V27.579l2.73,0.343v8.196L1.018,37.938z M2.575,40.882l2.218-3.336h13.771l2.219,3.336H2.575z M19.328,35.805v-7.872l2.729-0.355v10.048L19.328,35.805z";
      // var icon = {
      //   path: car,
      //   scale: 0.7,
      //   strokeColor: "white",
      //   strokeWeight: 0.1,
      //   fillOpacity: 1,
      //   fillColor: "#404040",
      //   offset: "5%",
      //   // rotation: parseInt(heading[i]),
      //   rotation: parseInt(heading_api),
      //   anchor: new google.maps.Point(10, 25), // orig 10,50 back of car, 10,0 front of car, 10,25 center of car
      // };

      // driver_marker.setIcon(icon);

      // old end

      // 10/06/2023
      let deg = parseInt(heading_api); // based on api heading value
      let doc_var: any = document.querySelector(
        'img[src="' + marker_img_url + '"]'
      );
      if (doc_var != null) {
        doc_var.style.transform = "rotate(" + deg + "deg)";
        doc_var.parentElement.style.overflow = "inherit";
      }

      const newPosition = new google.maps.LatLng(
        parseFloat(prevalue[0]?.lat),
        parseFloat(prevalue[0]?.lng)
      );
      // console.log("current_location prevalue :", prevalue);
      // console.log("newPosition.lat :", newPosition.lat());
      // console.log("newPosition.lng :", newPosition.lng());

      // Set the marker's position to the new position
      console.log("driver move new position :", newPosition);
      driver_marker.setPosition(newPosition);

      if (prevalue.length > 1) {
        let old = prevalue.shift();

        oldlatlng.push(old);
        SetOldLatLng(oldlatlng);
        // setCurrentLocation(prevalue);
        // console.log("Current location: " + current_location)
      }
      return [...prevalue];
    });
    // const start = new google.maps.LatLng(
    //   parseFloat(oldlatlng[oldlatlng.length - 1]?.lat),
    //   parseFloat(oldlatlng[oldlatlng.length - 1]?.lng)
    // );
    // const end = new google.maps.LatLng(
    //   parseFloat(current_location[0]?.lat),
    //   parseFloat(current_location[0]?.lng)
    // );

    // const heading = google.maps.geometry.spherical.computeHeading(start, end);
    // const heading_api = current_location[0]?.heading;

    // var car =
    //   "M17.402,0H5.643C2.526,0,0,3.467,0,6.584v34.804c0,3.116,2.526,5.644,5.643,5.644h11.759c3.116,0,5.644-2.527,5.644-5.644 V6.584C23.044,3.467,20.518,0,17.402,0z M22.057,14.188v11.665l-2.729,0.351v-4.806L22.057,14.188z M20.625,10.773 c-1.016,3.9-2.219,8.51-2.219,8.51H4.638l-2.222-8.51C2.417,10.773,11.3,7.755,20.625,10.773z M3.748,21.713v4.492l-2.73-0.349 V14.502L3.748,21.713z M1.018,37.938V27.579l2.73,0.343v8.196L1.018,37.938z M2.575,40.882l2.218-3.336h13.771l2.219,3.336H2.575z M19.328,35.805v-7.872l2.729-0.355v10.048L19.328,35.805z";
    // var icon = {
    //   path: car,
    //   scale: 0.7,
    //   strokeColor: "white",
    //   strokeWeight: 0.1,
    //   fillOpacity: 1,
    //   fillColor: "#404040",
    //   offset: "5%",
    //   // rotation: parseInt(heading[i]),
    //   rotation: parseInt(heading_api),
    //   anchor: new google.maps.Point(10, 25), // orig 10,50 back of car, 10,0 front of car, 10,25 center of car
    // };

    // driver_marker.setIcon(icon);
    // const newPosition = new google.maps.LatLng(
    //   parseFloat(current_location[0]?.lat),
    //   parseFloat(current_location[0]?.lng)
    // );
    // console.log("current_location :", current_location);
    // console.log("newPosition.lat :", newPosition.lat());
    // console.log("newPosition.lng :", newPosition.lng());

    // // Set the marker's position to the new position
    // driver_marker.setPosition(newPosition);

    // if (current_location.length > 1) {
    //   let old = current_location.shift();

    //   oldlatlng.push(old);
    //   SetOldLatLng(oldlatlng);
    //   setCurrentLocation(current_location);
    //   // console.log("Current location: " + current_location)
    // }
  }

  //   function driver_move(lat, lon) {
  //     console.log("driver move");
  //     console.log("driver move oldlatlng:", oldlatlng);

  //     SetCurrentLocation((prevalue) => {
  //         try {
  //             console.log("SetCurrentLocation prevalue:", prevalue);

  //             if (!oldlatlng.length || !prevalue.length) {
  //                 // console.error("Insufficient data for movement calculation");
  //                 return prevalue;
  //             }

  //             const start = new google.maps.LatLng(
  //                 parseFloat(oldlatlng[oldlatlng.length - 1].lat),
  //                 parseFloat(oldlatlng[oldlatlng.length - 1].lng)
  //             );
  //             const end = new google.maps.LatLng(
  //                 parseFloat(prevalue[0].lat),
  //                 parseFloat(prevalue[0].lng)
  //             );

  //             const heading = google.maps.geometry.spherical.computeHeading(start, end);
  //             const heading_api = prevalue[0].heading;

  //             let deg = parseInt(heading_api); // based on API heading value
  //             let doc_var = document.querySelector('img[src="' + marker_img_url + '"]');

  //             if (doc_var != null) {
  //                 doc_var.style.transform = "rotate(" + deg + "deg)";
  //                 doc_var.parentElement.style.overflow = "inherit";
  //             }

  //             const newPosition = new google.maps.LatLng(
  //                 parseFloat(prevalue[0].lat),
  //                 parseFloat(prevalue[0].lng)
  //             );

  //             console.log("driver move new position:", newPosition);

  //             if (driver_marker && typeof driver_marker.setPosition === "function") {
  //                 driver_marker.setPosition(newPosition);
  //             } else {
  //                 console.error("driver_marker is not defined or setPosition is not a function");
  //             }

  //             if (prevalue.length > 1) {
  //                 let old = prevalue.shift();
  //                 oldlatlng.push(old);
  //                 SetOldLatLng(oldlatlng);
  //             }

  //             return [...prevalue];
  //         } catch (error) {
  //             console.error("Error in driver_move:", error);
  //             return prevalue;
  //         }
  //     });
  // }

  async function cus_number(mobile) {

        if (context?.app_data.application?.platform == "android") {
          window.open("tel:" + mobile);
        } else {
          window.location.href = "tel:" + mobile;
        }
   
  }
  // async function del_partner_number() {
  //   let pass_data = {
  //     get: {
  //       brand: id_value.brand_id,
  //       outlet: id_value.outlet_id,
  //       order_id: props.order_details.id,
  //       callto: "delivery_partner",
  //       delivery_id:
  //         props.order_details?.delivery[props.delivery_index]?.hyperlocal_pickup
  //           ?.delivery_partner_mobile_delivery_id,
  //     },
  //   };
  //   let data_res: any = await api("/outlet/call", pass_data);
  //   if ((data_res.status_code = 200)) {
  //     // if(data_res?.response?.phone_number){
  //     //   window.open('tel:'+data_res?.response?.phone_number);
  //     // }
  //     if (data_res?.response?.phone_number) {
  //       if (context?.app_data.application?.platform == "android") {
  //         window.open("tel:" + data_res?.response?.phone_number);
  //       } else {
  //         window.location.href = "tel:" + data_res?.response?.phone_number;
  //       }
  //     }
  //   }
  // }
  async function del_partner_number() {
    let pass_data = {
      get: {
       
        order_id: props.order_details.id,
        callto: "delivery_partner",
        agent_order_id:   props.order_details.hasOwnProperty("pickup_drop")?
        props.order_details?.pickup_drop[
          props.shipment_index
        ]?.delivery_partner[props.delivery_index]?.id

        :
        props.order_details?.shipments[props.shipment_index]
        ?.super_delivery_order[0]?.delivery_partner[
        props.delivery_index].id

    
      
      },
    };
    let data_res: any = await api("/orders/call", pass_data);
    if ((data_res.status_code = 200)) {
      if (data_res?.response?.phone_number) {
        // window.open("tel:" + data_res?.response?.phone_number);
        document.location.href = "tel:" + data_res?.response?.phone_number;

      }
    }
  }

  const StatusPanel = () => {
    return (
      <>
        {
        props?.order_details?.hasOwnProperty("shipments") &&
        props.order_details?.shipments.length > 0 ? (
          <>
            {/* Delivery Time */}
            {/* <div className="d-flex align-items-cetner px-2">
              <h6 className="mb-0">Delivery in 25 mins</h6>
              <div className="ms-auto">
                <p className="mb-0 px-2 py-1 text-green bg-light-custom-green  fw-bold radius">
                  ON TIME
                </p>
              </div>
            </div> */}
            <div className="d-flex align-items-cetner px-2">
              <h6 className="mb-0">Delivery to</h6>
            </div>
            {/* Delivery Address */}
            <div className="px-2 py-3">
              <div className="d-flex align-items-center">
                {props.order_details?.customer?.delivery_address?.type ==
                "Home" ? (
                  <div className="pe-2">
                    <Ionicons name="home-outline" size={16} color="#ccc" />
                  </div>
                ) : null}
                {props.order_details?.customer?.delivery_address?.type ==
                "Work" ? (
                  <div className="pe-2">
                    <FontAwesome5 name="building" size={16} color="#ccc" />
                  </div>
                ) : null}
                {props.order_details?.customer?.delivery_address?.type ==
                "Others" ? (
                  <div className="pe-2">
                    <Ionicons
                      name="md-location-outline"
                      size={16}
                      color="#ccc"
                    />
                  </div>
                ) : null}

                <p className="text-dark fs-7">
                  {props.order_details?.customer?.name}
                </p>
              </div>
              <div className="d-flex">
              <div>{props.order_details?.customer?.delivery_address?.mobile}</div>
<div className="ms-2">
              {props.order_details?.customer?.delivery_address?.hasOwnProperty(
                        "mobile"
                      ) ? (
                        props.order_details?.customer?.delivery_address?.mobile &&
                          <img
                          onClick={() => {cus_number( props.order_details?.customer?.delivery_address?.mobile)}}
                            src={require("../../assets/icon/phone.png")}
                            className="bank-img"
                          />
                        
                      ) : null}
                      </div>
                      </div>

              {props.order_details?.customer?.delivery_address?.map_address !=
              "" ? (
                <p className="text-gray v-small pt-2">
                  {props.order_details?.customer?.delivery_address?.map_address}
                </p>
              ) : (
                <p className="text-gray v-small pt-2">
                  {props.order_details?.customer?.delivery_address?.door_no},
                  {props.order_details?.customer?.delivery_address?.address},
                  {props.order_details?.customer?.delivery_address?.city},
                  {props.order_details?.customer?.delivery_address?.state},
                  {props.order_details?.customer?.delivery_address?.country},
                  {props.order_details?.customer?.delivery_address?.pincode}
                </p>
              )}
            </div>


            {/* Loading bar */}
            {/* <div className="py-3 px-2">
              <div className="progress">
                <div
                  className={"progress-bar w-50 bg-prime1"}
                  // className={"progress-bar w-25 bg-prime1"}
                ></div>
              </div>
            </div> */}

            <div className="p-2">
              <div className="border p-2 radius">
                {/* resturant preparing food */}
                {/* {show_prepare_food ? (
                  <div className="d-flex pb-3 border-bottom">
                    <div className=" border radius p-0">
                      <img
                        src={require("../../../assets/img/food8.jpg")}
                        className="delivery-img"
                      />
                    </div>
                    <div className=" px-2 d-flex align-items-center w-100">
                      <p className="text-darkgray">
                        <b className="text-dark">
                          {props.order_details?.brand_name}
                        </b>{" "}
                        {
                          props.order_details?.delivery[props.delivery_index]
                            ?.status
                        }
                      </p>
                      <div className="py-2 px-3 cursor center ms-auto">
                        <a href={"tel:" + props.order_details?.outlet_mobile}>
                          <img
                            src={require("../../../assets/icon/phone.png")}
                            className="bank-img"
                          />
                        </a>
                      </div>
                    </div>
                  </div>
                ) : null} */}

                {/* customer details */}
                <div className="d-flex pb-3 border-bottom">
                  <div className=" border radius p-0">
                    {props.order_details?.customer?.hasOwnProperty("profile") &&
                    props.order_details?.customer?.profile != null ? (
                      <img
                        src={props.order_details?.customer?.profile}
                        className="delivery-img"
                      />
                    ) : (
                      <img
                        src={require("../../assets/img/12.jpg")}
                        className="delivery-img"
                      />
                    )}
                  </div>
                  <div className=" px-2 d-flex align-items-center w-100">
                    <p className="text-darkgray">
                      <b className="text-dark">
                        {props.order_details?.customer?.name}
                      </b>
                    </p>
                    <div className="py-2 px-3 cursor center ms-auto">
                      
                      {props.order_details?.customer?.hasOwnProperty(
                        "mobile"
                      ) ? (
                         props.order_details?.customer?.mobile &&
                          <img
                          onClick={() => {cus_number(props.order_details?.customer?.mobile)}}
                            src={require("../../assets/icon/phone.png")}
                            className="bank-img"
                          />
                        
                      ) : null}
                    </div>
                  </div>
                </div>

                {/* looking for delivery person */}
                {show_looking_for_delivery_person ? (
                  <div className="d-flex pt-3 align-items-center">
                    <div className="border radius p-0 ">
                      <div className="delivery-img center">
                        <FontAwesome5 name="user" size={16} color="#555" />
                      </div>
                    </div>
                    <div className="px-2">
                      <div className="d-flex align-items-center">
                        <p className="text-darkgray">
                          We are looking for a delivery partner to pick your
                          order.
                        </p>
                      </div>
                    </div>
                  </div>
                ) : null}

                {/* delivery person on the way to pickup order */}
                {show_delivery_person && (

props.order_details?.shipments[0]?.porter_order ? 
renderPorterDeliveries(props.order_details?.shipments[0]?.porter_delivery_order):
                   <div className="d-flex pt-3">
                   <div className="border radius p-0">
                     {props.order_details?.shipments[props.shipment_index]
                       ?.super_delivery_order[0]?.delivery_partner[
                       props.delivery_index
                     ]?.delivery_partner.profile != null &&
                     props.order_details?.shipments[props.shipment_index]
                       ?.super_delivery_order[0]?.delivery_partner[
                       props.delivery_index
                     ]?.delivery_partner.profile != "" ? (
                       <img
                         src={
                           props.order_details?.shipments[props.shipment_index]
                             ?.super_delivery_order[0]?.delivery_partner[
                             props.delivery_index
                           ]?.delivery_partner.profile
                         }
                         className="delivery-img"
                       />
                     ) : // <img
                     //   src={require("../../../assets/img/prof.jpg")}
                     //   className="delivery-img"
                     // />
                     null}
                     {/* <img
                       src={require("../../../assets/img/prof.jpg")}
                       className="delivery-img"
                     /> */}
                   </div>
                   <div className="px-2 d-flex align-items-center w-100">
                     <p className="text-darkgray">
                       <b className="text-dark fs-7">
                         {
                           props.order_details?.shipments[props.shipment_index]
                             ?.super_delivery_order[0]?.delivery_partner[
                             props.delivery_index
                           ]?.delivery_partner.name
                         }
                       </b>{" "}
                       {
                         props.order_details?.shipments[props.shipment_index]
                           ?.super_delivery_order[0]?.delivery_partner[
                           props.delivery_index
                         ]?.status
                       }
                     </p>
                     <div className="py-2 px-3 cursor center ms-auto">
                     
                     
                         <img
                         onClick={() => del_partner_number()}
                           src={require("../../assets/icon/phone.png")}
                           className="bank-img"
                         />
                      
                      
                     </div>
                   </div>
                 </div>
                ) }

                {/* delivery person on the way to delivery */}
                {/* {show_on_the_way_delivery ? (
                  <div>
                    <div className="d-flex pt-3 align-items-center">
                      <div className="border radius p-0">
                        <img
                          src={require("../../../assets/img/prof.jpg")}
                          className="delivery-img"
                        />
                      </div>
                      <div className="px-2 d-flex align-items-center w-100">
                        <p className="text-darkgray">
                          <b className="text-dark fs-7">
                            {
                              props.order_details?.delivery[props.delivery_index]
                                ?.hyperlocal_pickup?.delivery_partner_name
                            }
                          </b>{" "}
                          {
                            props.order_details?.delivery[props.delivery_index]
                              ?.hyperlocal_pickup?.status
                          }
                        </p>
                        <div className="px-3 py-2 cursor center ms-auto">
                          <img
                            src={require("../../../assets/icon/phone.png")}
                            className="bank-img"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                ) : null} */}

                {/* {show_reached_location ? (
                  <div>
                    <div className="d-flex pt-3 align-items-center">
                      <div className="border radius p-0">
                        <img
                          src={require("../../../assets/img/prof.jpg")}
                          className="delivery-img"
                        />
                      </div>
                      <div className="px-2 d-flex align-items-center w-100">
                        <p className="text-darkgray">
                          <b className="text-dark fs-7">
                            {
                              props.order_details?.delivery[props.delivery_index]
                                ?.hyperlocal_pickup?.delivery_partner_name
                            }
                          </b>{" "}
                          {
                            props.order_details?.delivery[props.delivery_index]
                              ?.hyperlocal_pickup?.status
                          }
                        </p>
                        <div className="px-3 py-2 cursor center ms-auto">
                          <img
                            src={require("../../../assets/icon/phone.png")}
                            className="bank-img"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                ) : null} */}

                {/* {show_paymet_collected ? (
                  <div>
                    <div className="d-flex pt-3 align-items-center">
                      <div className="border radius p-0">
                        <img
                          src={require("../../../assets/img/prof.jpg")}
                          className="delivery-img"
                        />
                      </div>
                      <div className="px-2 d-flex align-items-center w-100">
                        <p className="text-darkgray">
                          <b className="text-dark fs-7">
                            {props.order_details?.delivery_person?.name}
                            {
                              props.order_details?.delivery[props.delivery_index]
                                ?.hyperlocal_pickup?.delivery_partner_name
                            }
                          </b>{" "}
                          {
                            props.order_details?.delivery[props.delivery_index]
                              ?.hyperlocal_pickup?.status
                          }
                        </p>
                        <div className="px-3 py-2 cursor center ms-auto">
                          <img
                            src={require("../../../assets/icon/phone.png")}
                            className="bank-img"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                ) : null} */}

                {/* {show_delivery_completed ? (
                  <div>
                    <div className="d-flex py-2">
                      <div className="px-2">
                        <div className="d-flex align-items-center">
                          <p className="text-green fs-7 fw-bold">
                            Delivery Completed!
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : null} */}

                {/* {show_order_completed ? (
                  <div>
                    <div className="d-flex py-2">
                      <div className="px-2">
                        <div className="d-flex align-items-center">
                          <p className="text-green fs-7 fw-bold">
                            Order Completed!
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : null} */}
              </div>
            </div>

            <div className="border p-2 radius mb-4">
            <div className="d-flex pt-2">
                          <img
                            src={require("../../assets/icon/store.png")}
                            className="type-img"
                          />
                          <div className="ps-2">
                            <p className="extra-small">Brand Name</p>
                            <p className=" pt-1 text-dark fw-bold">
                              { props.order_details?.seller?.brand_name}
                            </p>
                          </div>
                          <div className="ms-auto">
              {props.order_details?.seller?.hasOwnProperty(
                        "contacts"
                      ) ? (
                        props.order_details?.seller?.contacts &&
                          <img
                          onClick={() => {cus_number( props.order_details?.seller?.contacts[0]?.mobile)}}
                            src={require("../../assets/icon/phone.png")}
                            className="bank-img"
                          />
                        
                      ) : null}
                      </div>
                        </div>
                          <div className="d-flex mt-2 ">
                          <img
                            src={require("../../assets/icon/outlet.png")}
                            className="type-img"
                          />
                          <div className="ps-2">
                            <p className="extra-small">Outlet Name</p>
                            <p className=" pt-1 text-dark fw-bold">
                              {props.order_details?.seller?.outlet?.name}
                            </p>
                          </div>
                        </div>
                        </div>

          </>
        ) :
         (
          props?.order_details?.hasOwnProperty("pickup_drop") &&
          // props.order_details?.pickup_drop[props.shipment_index]?.status_id ==
          //   4 
            // && 
            (
            <>
              {/* Delivery Time */}
              {/* <div className="d-flex align-items-cetner px-2">
          <h6 className="mb-0">Delivery in 25 mins</h6>
          <div className="ms-auto">
            <p className="mb-0 px-2 py-1 text-green bg-light-custom-green  fw-bold radius">
              ON TIME
            </p>
          </div>
        </div> */}
              <div className="d-flex align-items-cetner px-2">
                <h6 className="mb-0">Delivery to</h6>
              </div>
              {/* Delivery Address */}
              <div className="px-2 py-3">
                <div className="d-flex align-items-center">
                  {props.order_details?.customer?.delivery_address?.type ==
                  "Home" ? (
                    <div className="pe-2">
                      <Ionicons name="home-outline" size={16} color="#ccc" />
                    </div>
                  ) : null}
                  {props.order_details?.customer?.delivery_address?.type ==
                  "Work" ? (
                    <div className="pe-2">
                      <FontAwesome5 name="building" size={16} color="#ccc" />
                    </div>
                  ) : null}
                  {props.order_details?.customer?.delivery_address?.type ==
                  "Others" ? (
                    <div className="pe-2">
                      <Ionicons
                        name="md-location-outline"
                        size={16}
                        color="#ccc"
                      />
                    </div>
                  ) : null}

                  <p className="text-dark fs-7">
                    {props.order_details?.customer?.name}
                  </p>
                </div>

                {props.order_details?.customer?.delivery_address?.map_address !=
                "" ? (
                  <p className="text-gray v-small pt-2">
                    {
                      props.order_details?.customer?.delivery_address
                        ?.map_address
                    }
                  </p>
                ) : (
                  <p className="text-gray v-small pt-2">
                    {props.order_details?.customer?.delivery_address?.door_no},
                    {props.order_details?.customer?.delivery_address?.address},
                    {props.order_details?.customer?.delivery_address?.city},
                    {props.order_details?.customer?.delivery_address?.state},
                    {props.order_details?.customer?.delivery_address?.country},
                    {props.order_details?.customer?.delivery_address?.pincode}
                  </p>
                )}
              </div>
              {/* Loading bar */}
              {/* <div className="py-3 px-2">
          <div className="progress">
            <div
              className={"progress-bar w-50 bg-prime1"}
              // className={"progress-bar w-25 bg-prime1"}
            ></div>
          </div>
        </div> */}

              <div className="p-2">
                <div className="border p-2 radius">
                  {/* resturant preparing food */}
                  {/* {show_prepare_food ? (
              <div className="d-flex pb-3 border-bottom">
                <div className=" border radius p-0">
                  <img
                    src={require("../../../assets/img/food8.jpg")}
                    className="delivery-img"
                  />
                </div>
                <div className=" px-2 d-flex align-items-center w-100">
                  <p className="text-darkgray">
                    <b className="text-dark">
                      {props.order_details?.brand_name}
                    </b>{" "}
                    {
                      props.order_details?.delivery[props.delivery_index]
                        ?.status
                    }
                  </p>
                  <div className="py-2 px-3 cursor center ms-auto">
                    <a href={"tel:" + props.order_details?.outlet_mobile}>
                      <img
                        src={require("../../../assets/icon/phone.png")}
                        className="bank-img"
                      />
                    </a>
                  </div>
                </div>
              </div>
            ) : null} */}

                  {/* customer details */}
                  <div className="d-flex pb-3 border-bottom">
                    <div className=" border radius p-0">
                      {props.order_details?.customer?.hasOwnProperty(
                        "profile"
                      ) && props.order_details?.customer?.profile != null ? (
                        <img
                          src={props.order_details?.customer?.profile}
                          className="delivery-img"
                        />
                      ) : (
                        <img
                          src={require("../../assets/img/12.jpg")}
                          className="delivery-img"
                        />
                      )}
                    </div>
                    <div className=" px-2 d-flex align-items-center w-100">
                      <p className="text-darkgray">
                        <b className="text-dark">
                          {props.order_details?.customer?.name}
                        </b>
                      </p>
                      <div className="py-2 px-3 cursor center ms-auto">
                  {props.order_details?.customer?.hasOwnProperty(
                    "mobile"
                  ) ? (
                     props.order_details?.customer?.mobile &&
                      <img
                      onClick={() => {cus_number(props.order_details?.customer?.mobile)}}
                        src={require("../../assets/icon/phone.png")}
                        className="bank-img"
                      />
                    
                  ) : null}
                </div>
                    </div>
                  </div>

                  {/* looking for delivery person */}
                  {show_looking_for_delivery_person ? (
                    <div className="d-flex pt-3 align-items-center">
                      <div className="border radius p-0 ">
                        <div className="delivery-img center">
                          <FontAwesome5 name="user" size={16} color="#555" />
                        </div>
                      </div>
                      <div className="px-2">
                        <div className="d-flex align-items-center">
                          <p className="text-darkgray">
                            We are looking for a delivery partner to pick your
                            order.
                          </p>
                        </div>
                      </div>
                    </div>
                  ) : null}

                  {/* delivery person on the way to pickup order */}
                  {show_delivery_person && (
props.order_details?.pickup_drop_porter_order ? 
renderPorterDeliveries(props.order_details?.pickup_drop_porter_delivery_order):
                    <div className="d-flex pt-3">
                      <div className="border radius p-0">
                        {props.order_details?.pickup_drop[props.shipment_index]
                          ?.delivery_partner[props.delivery_index]
                          ?.delivery_partner.profile != null &&
                        props.order_details?.pickup_drop[props.shipment_index]
                          ?.delivery_partner[props.delivery_index]
                          ?.delivery_partner.profile != "" ? (
                          <img
                            src={
                              props.order_details?.pickup_drop[
                                props.shipment_index
                              ]?.delivery_partner[props.delivery_index]
                                ?.delivery_partner.profile
                            }
                            className="delivery-img"
                          />
                        ) : // <img
                        //   src={require("../../../assets/img/prof.jpg")}
                        //   className="delivery-img"
                        // />
                        null}
                        {/* <img
                    src={require("../../../assets/img/prof.jpg")}
                    className="delivery-img"
                  /> */}
                      </div>
                      <div className="px-2 d-flex align-items-center w-100">
                        <p className="text-darkgray">
                          <b className="text-dark fs-7">
                            {
                              props.order_details?.pickup_drop[
                                props.shipment_index
                              ]?.delivery_partner[props.delivery_index]
                                ?.delivery_partner.name
                            }
                          </b>{" "}
                          {
                            props.order_details?.pickup_drop[
                              props.shipment_index
                            ]?.delivery_partner[props.delivery_index]?.status
                          }
                        </p>
                        <div className="py-2 px-3 cursor center ms-auto">
                    {/* {
                       
                       props.order_details?.pickup_drop[
                        props.shipment_index
                      ]?.delivery_partner[props.delivery_index]?.delivery_partner_mobile && */}
                    
                  
                      <img
                      onClick={() => del_partner_number()}
                        src={require("../../assets/icon/phone.png")}
                        className="bank-img"
                      />
                    {/* } */}
                   
                  </div>
                      </div>
                    </div>
                  ) }

                  {/* delivery person on the way to delivery */}
                  {/* {show_on_the_way_delivery ? (
              <div>
                <div className="d-flex pt-3 align-items-center">
                  <div className="border radius p-0">
                    <img
                      src={require("../../../assets/img/prof.jpg")}
                      className="delivery-img"
                    />
                  </div>
                  <div className="px-2 d-flex align-items-center w-100">
                    <p className="text-darkgray">
                      <b className="text-dark fs-7">
                        {
                          props.order_details?.delivery[props.delivery_index]
                            ?.hyperlocal_pickup?.delivery_partner_name
                        }
                      </b>{" "}
                      {
                        props.order_details?.delivery[props.delivery_index]
                          ?.hyperlocal_pickup?.status
                      }
                    </p>
                    <div className="px-3 py-2 cursor center ms-auto">
                      <img
                        src={require("../../../assets/icon/phone.png")}
                        className="bank-img"
                      />
                    </div>
                  </div>
                </div>
              </div>
            ) : null} */}

                  {/* {show_reached_location ? (
              <div>
                <div className="d-flex pt-3 align-items-center">
                  <div className="border radius p-0">
                    <img
                      src={require("../../../assets/img/prof.jpg")}
                      className="delivery-img"
                    />
                  </div>
                  <div className="px-2 d-flex align-items-center w-100">
                    <p className="text-darkgray">
                      <b className="text-dark fs-7">
                        {
                          props.order_details?.delivery[props.delivery_index]
                            ?.hyperlocal_pickup?.delivery_partner_name
                        }
                      </b>{" "}
                      {
                        props.order_details?.delivery[props.delivery_index]
                          ?.hyperlocal_pickup?.status
                      }
                    </p>
                    <div className="px-3 py-2 cursor center ms-auto">
                      <img
                        src={require("../../../assets/icon/phone.png")}
                        className="bank-img"
                      />
                    </div>
                  </div>
                </div>
              </div>
            ) : null} */}

                  {/* {show_paymet_collected ? (
              <div>
                <div className="d-flex pt-3 align-items-center">
                  <div className="border radius p-0">
                    <img
                      src={require("../../../assets/img/prof.jpg")}
                      className="delivery-img"
                    />
                  </div>
                  <div className="px-2 d-flex align-items-center w-100">
                    <p className="text-darkgray">
                      <b className="text-dark fs-7">
                        {props.order_details?.delivery_person?.name}
                        {
                          props.order_details?.delivery[props.delivery_index]
                            ?.hyperlocal_pickup?.delivery_partner_name
                        }
                      </b>{" "}
                      {
                        props.order_details?.delivery[props.delivery_index]
                          ?.hyperlocal_pickup?.status
                      }
                    </p>
                    <div className="px-3 py-2 cursor center ms-auto">
                      <img
                        src={require("../../../assets/icon/phone.png")}
                        className="bank-img"
                      />
                    </div>
                  </div>
                </div>
              </div>
            ) : null} */}

                  {/* {show_delivery_completed ? (
              <div>
                <div className="d-flex py-2">
                  <div className="px-2">
                    <div className="d-flex align-items-center">
                      <p className="text-green fs-7 fw-bold">
                        Delivery Completed!
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            ) : null} */}

                  {/* {show_order_completed ? (
              <div>
                <div className="d-flex py-2">
                  <div className="px-2">
                    <div className="d-flex align-items-center">
                      <p className="text-green fs-7 fw-bold">
                        Order Completed!
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            ) : null} */}
                </div>
              </div>
            </>
          )
        )}
      </>
    );
  };

  function get_mins(totalSeconds: any) {
    const minutes = Math.floor(totalSeconds / 60);
    const seconds = totalSeconds % 60;
    const result = `${padTo2Digits(minutes)}:${padTo2Digits(seconds)}`;
    return result;
  }

  function padTo2Digits(num: any) {
    return num.toString().padStart(2, "0");
  }

  return (
    <div
      className="modal fade"
      id="MapTracker"
      tabIndex={-1}
      aria-labelledby="ModalLabel"
      aria-hidden="true"
    >
      <div className="">
        {
        props?.order_details?.hasOwnProperty("shipments") &&
        props.order_details?.shipments.length > 0 ? (
          <div className="modal-dialog  modal-fullscreen" data-backdrop="false">
            {/* <div className="modal-content">
         
              <div className="modal-header map-header">
              
                <div className="ps-1 pe-0 w-100">
                  <p
                    className="fs-7 text-dark mb-0 fw-500"
                    onClick={() => {
                      console.log("props :", props);
                    }}
                  >
                    Order ID: #{props.order_details?.id}
                  </p>

                  <div className="d-flex align-items-center ">
                    <p className="text-gray pe-1">
                    
                      {props.order_details?.products?.length} Item | ₹
                      {props.order_details?.amount}
                    </p>
                  </div>
                </div>
           
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div className="map-modal-body p-0">
                <div className="row p-0 ">
           
                  <div className="col-md-7 col-12 p-0">
                    <div id="map-live-track"></div>
                  </div>
                 
                  {width > 720 ? (
                  
                    <div className="col-md-5 pt-3 bg-fff">
                      <StatusPanel />
                    </div>
                  ) : null}

                  {width < 720 ? (
                  
                    <div className="map-over center  bg-fff">
                      <SwipeableBottomSheet overflowHeight={250} >
                        <div className="p-2 bg-fff sticky-top custom-swipe-shadow">
                          <div className="center pb-3">
                            <hr className="hr-drawer" />
                          </div>

                          <StatusPanel />
                        </div>
                      </SwipeableBottomSheet>
                    </div>
                  ) : null}
                </div>
              </div>
            </div> */}
            {renderModalContent()}
          </div>
        ) : (
          props?.order_details?.hasOwnProperty("pickup_drop") &&
          props.order_details?.sector_id == 4 && (
            <div
              className="modal-dialog  modal-fullscreen"
              data-backdrop="false"
            >
              {renderModalContent()}

              {/* <div className="modal-content">
         
              <div className="modal-header map-header">
              
                <div className="ps-1 pe-0 w-100">
                  <p
                    className="fs-7 text-dark mb-0 fw-500"
                    onClick={() => {
                      console.log("props :", props);
                    }}
                  >
                    Order ID: #{props.order_details?.id}
                  </p>

                  <div className="d-flex align-items-center ">
                    <p className="text-gray pe-1">
                    
                      {/* {props.order_details?.products?.length} Item | ₹
                      {props.order_details?.amount} */}
              {/* </p>
                  </div>
                </div>
           
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div className="map-modal-body p-0">
                <div className="row p-0 ">
           
                  <div className="col-md-7 col-12 p-0">
                    <div id="map-live-track"></div>
                  </div>
                 
                  {width > 720 ? (
                  
                    <div className="col-md-5 pt-3 bg-fff">
                      <StatusPanel />
                    </div>
                  ) : null}

                  {width < 720 ? (
                  
                    <div className="map-over center  bg-fff">
                      <SwipeableBottomSheet overflowHeight={250} >
                        <div className="p-2 bg-fff sticky-top custom-swipe-shadow">
                          <div className="center pb-3">
                            <hr className="hr-drawer" />
                          </div>

                          <StatusPanel />
                        </div>
                      </SwipeableBottomSheet>
                    </div>
                  ) : null}
                </div>
              </div>
            </div>  */}
            </div>
          )
        )}
      </div>
    </div>
  );
}
